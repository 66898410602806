import React from "react";
import { CURRENCY } from "../../../contants";
import ModalContainer from "../ModalContainer";
import SmartTable from "../SmartTable";

// const styles = {
//   variantsContainer: {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "flex-start",
//     alignItems: "flex-start",
//     width: "auto",
//   },
//   rowStyle: {
//     flex: 3,
//   },
//   rowContainerStyle: {
//     cursor: "cell",
//   },
// };

const MergeTableModal = ({
  open,
  onClose,
  tables,
  MergeTable,
}) => {
  const [selectedData, setSelectedData] = React.useState([]);
  const submitData = () => {
    MergeTable(selectedData.filter((item) => item.selected));
  };
  const headers = [
    { title: "Table Number", key: "tableNumber" },
  ];
  return (
    <ModalContainer
      open={open}
      onClose={() => {
        onClose();
        // setFormErrors();
        // reset();
      }}
      title={`Tables`}
      size="l"
    >
        <SmartTable
          tableData={tables}
          setTableData={(data) => setSelectedData(data)}
          headers={headers}
          sortable={true}
          selectable={true}
          paginated={true}
          rowsPerPage={5}
        />
      <div class="form-group mb-0">
        <button
          // disabled={isLoading}
          onClick={() => submitData()}
          class="btn btn-gradient-primary waves-effect waves-light"
        >
          Submit
        </button>
        <button
          type="reset"
          class="btn btn-gradient-danger waves-effect ml-3"
          onClick={() => onClose()}
        >
          Cancel
        </button>
      </div>
    </ModalContainer>
  );
};

export default MergeTableModal;
