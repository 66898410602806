const itemToppingsApi = {
    CREATE_ITEM_TOPPINGS: `/api/restaurant/itemtopping/create`,
    UPDATE_ITEM_TOPPINGS: `/api/restaurant/itemtopping/update`,
    DELETE_ITEM_TOPPINGS: `/api/restaurant/itemtopping/delete`,
    GET_ITEM_TOPPINGS: `/api/restaurant/itemtopping`,
    
    UPDATE_ITEM_TOPPING_FIELD: `/api/restaurant/items/toppingupdatefield`,
    DELETE_ITEM_TOPPING_FIELD: `/api/restaurant/items/toppingdeletefield`,
  };
  export default itemToppingsApi;
  