import { UPDATE_NETWORK_STATUS, UPDATE_APP_OFFLINE_STATUS } from "../types";

const initialState = {
  isOnline: navigator.onLine,
  isAppOffline: false,
};

const networkStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NETWORK_STATUS:
      return {
        ...state,
        isOnline: action.payload,
      };

      case UPDATE_APP_OFFLINE_STATUS:
        return {
          ...state,
          isAppOffline: action.payload,
        };

    default:
      return state;
  }
};

export default networkStatusReducer;
