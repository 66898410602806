import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SmartTable from "../../components/common/SmartTable";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import {
  DATEFORMAT,
  dateRanges,
  BASEURL,
  DATETIMEFORMAT,
} from "../../contants";
import axios from "axios";
import { getOrderSummaryData } from "../../redux/action/tempActions";

const PageTitle = "Order Summary Data";

const OrderSummaryData = () => {
  const { role, restaurantId, branchId } = useSelector((state) => state.user);

  const branches = useSelector((state) => state.branch.allBranches);
  const [selectedBranch, setSelectedBranch] = React.useState(branchId);
  const dispatch = useDispatch();

  const currBranchId = branchId || selectedBranch;

  const [actionData, setActionData] = React.useState();

  const getAllData = async () => {
    // const response = await axios.post(`${BASEURL}api/restaurant/tempsummary/`, { start: state.start.format('YYYY-MM-DD'), end: state.end.format('YYYY-MM-DD'), branchId: currBranchId, resdb: restaurantId });
    // console.log("response : ", response.data.data)
    dispatch(
      getOrderSummaryData({
        start: state.start.format("YYYY-MM-DD"),
        end: state.end.format("YYYY-MM-DD"),
        branchId: currBranchId,
        resdb: restaurantId,
      })
    )
      .then((data) => setActionData(data.payload.data.data))
      .catch((error) => console.log(error));
    // setActionData(response.data.data)
  };

  const [state, setState] = React.useState({
    start: moment(),
    end: moment(),
  });
  const { start, end } = state;

  const handleCallback = (start, end) => {
    // props.setValue({ start, end });
    // onChange(setState({ start, end }));
    console.log("start: ", start, " end: ", end);
    setState({ start, end });
  };

  const headers = [
    {
      title: "Branch Name",
      key: "branchName",
    },
    { title: "Shift", key: "shift" },
    { title: "Dine In", key: "dineIn", isCurrency: true },
    { title: "Parcel", key: "parcel", isCurrency: true },
    { title: "Home Delivery", key: "homeDelivery", isCurrency: true },
    { title: "Bank", key: "bank", isCurrency: true },
    { title: "Cash", key: "cash", isCurrency: true },
    { title: "Wallet", key: "wallet", isCurrency: true },
    { title: "Discount", key: "discount", isCurrency: true },
    { title: "Other Charges", key: "otherCharges", isCurrency: true },
    { title: "expenseCash", key: "expenseCash", isCurrency: true },
    { title: "expenseBank", key: "expenseBank", isCurrency: true },
    { title: "Opening Balance", key: "openingBalance", isCurrency: true },
    { title: "Closing Balance", key: "closingBalance", isCurrency: true },
    {
      title: "Date",
      key: "date",
      renderRow: (row) => moment(row.date).format(DATETIMEFORMAT),
    },
  ];

  React.useEffect(() => {
    getAllData();
  }, [selectedBranch, state]);

  const DatePicker = (action) => (
    <div class="">
      <DateRangePicker
        initialSettings={{
          startDate: start.toDate(),
          endDate: end.toDate(),

          locale: {
            format: DATEFORMAT,
          },
          maxDate: new Date(),

          ranges: dateRanges,
        }}
        onCallback={handleCallback}
      >
        <input type="text" class="form-control" />
      </DateRangePicker>
    </div>
  );

  const headerComponents = {
    restaurantadmin: [DatePicker],
    branchadmin: [DatePicker],
  };

  return (
    <>
      <div class="page-content-tab">
        <SmartTable
          headerComponents={headerComponents[role]}
          title={PageTitle}
          // headActions={[AddAction]}
          tableData={actionData}
          headers={headers}
          sortable={false}
          paginated={true}
          rowsPerPage={5}
        />
      </div>
    </>
  );
};

export default OrderSummaryData;
