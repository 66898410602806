const branchApi = {
  GET_ALL_BRANCHES: `/api/restaurant/branches`,
  CREATE_BRANCH: "/api/restaurant/branches/create",
  UPDATE_BRANCH: "/api/restaurant/branches/update",
  DELETE_BRANCH: `/api/restaurant/branches/delete`,

  GET_ALL_BRANCH_CUSTOMER : "/api/restaurant/customerdata",
  UPDATE_RECEIPT_MESSAGE: `/api/restaurant/branches/updateReceipt`,
};
export default branchApi;
