const itemAvailabilityApi = {

    GET_ALL_ITEM_AVAILABILITY: `/api/restaurant/itemavailability`,
    CREATE_ITEM__AVAILABILITY: `/api/restaurant/itemavailability/create`,
    UPDATE_ITEM_AVAILABILITY: `/api/restaurant/itemavailability/update`,
    DELETE_ITEM_AVAILABILITY: `/api/restaurant/itemavailability/delete`,

  };

export default itemAvailabilityApi;
  