import price from "./calculatePrice";

const calculateOrderTotals = (
  activeOrder,
  cgst,
  sgst,
  otherCharges,
  discount,
  tableType,
) => {
  // console.log(tableType);
  let itemsTotal = 0;
  let cgstCharges = 0;

  let sgstCharges = 0;
  let grandTotal = 0;
  let grandTotalWithoutDiscount = 0;

  let tablePrice = 0;
  let taxTotal = 0;
  let subTotal  = 0;

  if (activeOrder?.orderItems) {
    activeOrder?.orderItems.forEach((item) => {
      let itemTotal = item.quantity * (price(tableType, item) || item.itemPrice);
      itemsTotal += itemTotal;

      subTotal += itemTotal - (itemTotal * ((item?.cgst || 0) + (item?.sgst || 0)) / 100);
      // let toppingPrice = 0;
      // item?.activeTopping?.forEach((topping) => toppingPrice += topping.itemPrice);
      // itemsTotal += toppingPrice;
      let itemcgst = item?.cgst || 0;
      let itemsgst = item?.sgst || 0;
      cgstCharges += (itemTotal * itemcgst) / 100;
      sgstCharges += (itemTotal * itemsgst) / 100;
    });

    // cgstCharges = (itemsTotal * cgst) / 100;

    // sgstCharges = (itemsTotal * sgst) / 100;
    tablePrice = activeOrder.tablePrice;
  }
  grandTotal =
    itemsTotal +
    // cgstCharges +
    // sgstCharges +
    tablePrice +
    parseFloat(otherCharges || 0) -
    parseFloat(discount || 0);

  // console.log("grandTotal= ",grandTotal);  

  grandTotalWithoutDiscount =
    itemsTotal +
    // cgstCharges +
    // sgstCharges +
    tablePrice +
    parseFloat(otherCharges || 0);

  taxTotal = parseFloat(cgstCharges + sgstCharges);
  return {
    itemsTotal,
    subTotal,
    cgstCharges,
    sgstCharges,
    otherCharges,
    taxTotal,
    discount,
    tablePrice,
    refId: activeOrder?.refId,
    grandTotal: Math.ceil(grandTotal),
    grandTotalWithoutDiscount: grandTotalWithoutDiscount.toFixed(2),
    cgst,
    sgst,
  };
};
export default calculateOrderTotals;
