const price = ((tableType, data) => {
    // console.log(tableType);
    // console.log(data.priceForAC);
    let toppingPrice = 0;
    data?.activeTopping?.forEach((topping) => toppingPrice += topping.itemPrice || 0);
    
      switch(tableType){
      case "AC": return ((data.priceForAC || data.itemPrice) + toppingPrice);
      case "Non AC" : return ((data.priceForNonAC || data.itemPrice) + toppingPrice);
      case "Garden" : return ((data.priceForGarden || data.itemPrice) + toppingPrice);
      case "Online" : return ((data.priceForOnline || data.itemPrice) + toppingPrice);
      case "Hut": return ((data.priceForHut || data.itemPrice) + toppingPrice);
      case "Room Service": return ((data.priceForRoomService || data.itemPrice) + toppingPrice);
      case "Banquet Hall": return ((data.priceForBanquetHall || data.itemPrice) + toppingPrice);
      default : return (data.itemPrice + toppingPrice);
    };
 });

export default price;