import React from "react";
import { useSelector } from "react-redux";
import leftSideBarRoutes from "../../Routes/leftSideBarRoutes";
import View from "./view";

const LeftSideBar = ({ sideMenuOpen, setSideMenuOpen }) => {
  const role = useSelector((state) => state.user.role);

  const { isOnline, isAppOffline } = useSelector(
    (state) => state.networkStatus
  );

  if (isAppOffline || !isOnline) {
    return <View sidebarData={leftSideBarRoutes["branchadminOffline"]} />;
  }

  return (
    <View
      sidebarData={leftSideBarRoutes[role]}
      sideMenuOpen={sideMenuOpen}
      setSideMenuOpen={setSideMenuOpen}
    />
  );
};

export default LeftSideBar;
