import React from "react";
import { useSelector } from "react-redux";
import LoadingFullPage from "../../components/common/Loading/LoadingFullPage";
import getOrderNeccesaryData from "../../helpers/getOrderNeccesaryData";
import useFullscreenStatus from "../../hooks/useFullscreenStatus";
import useKitchenDisplay from "../../hooks/useKitchenDisplay";
import BottomPortion from "./BottomPortion";
import CenterPortion from "./CenterPortion/index";
import { useDispatch } from "react-redux";
// import { getPreviosOrders } from "../../redux/action/orderActions";
import { getAllBrancheCustomer } from "../../redux/action/branchActions";

// import moment from "moment";

import TopPortion from "./TopPortion";
import { getAllItemAvailability } from "../../redux/action/itemAvailability";
import { getOfflineActiveOrders,
  getOfflineAllItems,
  getOfflineTables,
  getOfflineAllTables,
  getOfflineItemCategories,
  getOfflineUser,
  getOfflineItemAvailability, 
  getOfflineLastOrderNumber} from "../../redux/action/offlineDataActions";
import getoffiledata from "../../helpers/readOfflineData";

const OrderDashboard = () => {
  const isElectron = window?.api?.isElectron;
  const {isOnline, isAppOffline} = useSelector((state) => state.networkStatus);
  const dispatch = useDispatch();

  const ready = getOrderNeccesaryData();
  const kitchen = useKitchenDisplay();

  const isFullScreen = useSelector((state) => state.util.isFullScreen);

  const ref = React.useRef(null);

  const { branchId } = useSelector((state) => state.user);
  // const { previousOrders } = useSelector((state) => state.order);
  // const [numberMatch, setnumberMatch] = React.useState([]);
  
  // const endDate = moment();
  // const startDate = moment();
  // startDate.subtract('1', 'years');

  // const getAllData = () => {
  //   dispatch(getPreviosOrders({ start: startDate, end: endDate, branchId: branchId }));
  //   dispatch(getAllBrancheCustomer());
  // };

  React.useEffect(async () => {
    if(isElectron && (isAppOffline || !isOnline)){
      // const data = await getoffiledata()
      // window.api.createFileOffline({fileName: "log", data: await getoffiledata("activeOrders")})
      dispatch(getOfflineActiveOrders(await getoffiledata("activeOrders")))
      dispatch(getOfflineAllItems(await getoffiledata("allItems")))
      dispatch(getOfflineTables(await getoffiledata("tables")))
      dispatch(getOfflineAllTables(await getoffiledata("allTables")))
      dispatch(getOfflineItemCategories(await getoffiledata("itemCategories")))
      dispatch(getOfflineUser(await getoffiledata("user")))
      dispatch(getOfflineItemAvailability(await getoffiledata("itemAvailability")))
      dispatch(getOfflineLastOrderNumber(await getoffiledata("orderCount"))) 
    }
    else{
      dispatch(getAllBrancheCustomer());
      dispatch(getAllItemAvailability());
    }
    // console.log(previousOrders);
  }, [branchId]);

  const [isFullscreen, setIsFullscreen] = useFullscreenStatus(
    ref,
    isFullScreen
  );

  // const data = useKitchenDisplay();
  return ready ? (
    <div
      ref={ref}
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TopPortion />

      <CenterPortion />

      <BottomPortion />
    </div>
  ) : (
    <LoadingFullPage />
  );
};

export default OrderDashboard;

// import React from "react";
// import LoadingFullPage from "../../components/common/Loading/LoadingFullPage";
// import getOrderNeccesaryData from "../../helpers/getOrderNeccesaryData";
// import BottomPortion from "./BottomPortion";
// import CenterPortion from "./CenterPortion/index";
// import LeftPortion from "./LeftPortion/index";
// import RightPortion from "./RightPortion/index";
// import TopPortion from "./TopPortion";

// const styles = {
//   topStyle: {
//     height: "100%",
//     flex: 1,
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-between",
//     alignItems: "center",
//     width: "100%",
//   },
// };
// const OrderDashboard = () => {
//   const ready = getOrderNeccesaryData();

//   return ready ? (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         height: "100%",
//         overflow: "hidden",
//       }}
//     >
//       <div style={{ width: "100%" }}>
//         <TopPortion />
//       </div>
//       <div style={styles.topStyle}>
//         {/* <LeftPortion /> */}
//         <CenterPortion />
//         {/* <RightPortion /> */}
//       </div>
//       <div class="row" style={{ width: "100%" }}>
//         <BottomPortion />
//       </div>
//     </div>
//   ) : (
//     <LoadingFullPage />
//   );
// };

// export default OrderDashboard;
