import React from "react";

const general = {
  cursor: "pointer",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  display: "inline-block",
  padding: "0.25em 0.4em",
  verticalAlign: "baseline",
  borderRadius: "0.25rem",
}
const success = {
  ...{...general},
  backgroundColor: "rgba(31, 140, 45, 0.15)",
  color: "#1f8c2d",
  boxShadow: "0px 0px 13px 0px rgb(31 140 45 / 5%)",
}

const danger = {
  ...{...general},
  backgroundColor: "rgba(168, 35, 35, 0.15)",
  color: "#a82323",
  boxShadow: "0px 0px 13px 0px rgb(168 35 35 / 5%)",
}

const QuntityStatusColumn = ({ data, status }) => {
  // console.log(data, status);
  return (
    <div>

      <span  
      style = {status === true ? success : danger}
      >
        {data}
      </span>
    </div>
  );
};

export default QuntityStatusColumn;
