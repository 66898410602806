import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TYPESOFORDERS } from "../../../contants";
import { activateOrderOffline, getOfflineLastOrderNumber } from "../../../redux/action/offlineDataActions";
import {
  setOrderType,
  addNewOtherOrder,
  activateOrder,
} from "../../../redux/action/orderActions";
import getoffiledata from "../../../helpers/readOfflineData";

const OrderTypeSelector = () => {
  const dispatch = useDispatch();
  const { selectedOrderTypeId } = useSelector((state) => state.order);

  const {isOnline, isAppOffline} = useSelector((state) => state.networkStatus);
  const isElectron = window?.api?.isElectron;

  const handleSelectType = async (type) => {
    // dispatch(activateOrder({ orderType: type.key, orderTypeId: type.value }));
    if((isAppOffline || !isOnline) && isElectron){
      dispatch(getOfflineLastOrderNumber(await getoffiledata("orderCount")))
      dispatch(activateOrderOffline({ orderType: type.key, orderTypeId: type.value }));
    }else{
      dispatch(activateOrder({ orderType: type.key, orderTypeId: type.value }));
    }
  };
  return (
    <div
      class="btn-group mr-0 pr-0 shadow-none"
      style={{ width: "100%" }}
      role="group"
      aria-label="Basic example"
    >
      {TYPESOFORDERS.map((type, index) => {
        return (
          <button
            type="button"
            onClick={() => index !== 0 && handleSelectType(type)}
            class={
              index === 0
                ? `btn  shadow-none btn-primary`
                : `btn  shadow-none btn-warning`
            }
          >
            {type.key}
          </button>
        );
      })}
    </div>
  );
};

export default OrderTypeSelector;
