import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootUrl } from "../../redux/types";
import useResponsive from "../../hooks/useResponsive";

const View = ({ handleLogout, sideMenuOpen, setSideMenuOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const { isMobile } = useResponsive();
  const { role, name, restaurantLogo, restaurantName, branchName } =
    useSelector((state) => state.user);

  const handleNavigate = (link) => history.push(link);

  const logoPath = RootUrl + "/" + restaurantLogo;

  return (
    <div className="topbar">
      <nav
        className={`navbar-custom ${
          isMobile ? (sideMenuOpen ? "active" : "") : ""
        }`}
        style={{
          paddingLeft: "25px",
          paddingRight: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {isMobile && (
          <div
            className="menu-open icon"
            onClick={() => setSideMenuOpen(!sideMenuOpen)}
            style={{ cursor: "pointer" }}
          >
            <i
              className={`mdi ${
                sideMenuOpen ? "mdi-window-close" : "mdi-menu"
              }`}
            ></i>
          </div>
        )}
        <ul className="list-unstyled topbar-nav mb-0">
          <li
            style={{ padding: "10px", cursor: "pointer" }}
            onClick={() => handleNavigate("/")}
          >
            <img
              src={role !== "superadmin" ? logoPath : "./images/logo.png"}
              style={{ height: "50px", width: "auto" }}
            />
          </li>
          <li className="hide-phone">
            <div className="nav-link waves-effect waves-light nav-user">
              <span className="ml-1 font-weight-bold nav-user-name hidden-sm">
                {restaurantName} {branchName && branchName}
              </span>
            </div>
          </li>
        </ul>
        <ul className="list-unstyled topbar-nav float-right mb-0">
          <li className="position-relative px-0">
            <div
              className="nav-link waves-effect waves-light nav-user px-0"
              onClick={() => setOpen(!open)}
              style={{ cursor: "pointer", position: "relative" }}
            >
              <i
                className="mdi mdi-account-circle-outline"
                style={{ fontSize: "24px" }}
              ></i>
            </div>
            {open && (
              <div
                className="card"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "100%",
                  marginTop: "10px",
                  zIndex: 1000,
                  width: "200px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div className="p-3">
                  <div className="text-muted">
                    <strong>Name:</strong> {name}
                  </div>
                  <div className="text-muted">
                    <strong>Role:</strong> {role}
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => handleLogout()}
                    style={{
                      cursor: "pointer",
                      padding: "10px 0",
                      borderTop: "1px solid #eaeaea",
                      marginTop: "10px",
                    }}
                  >
                    <i className="dripicons-exit text-muted mr-2"></i>
                    Logout
                  </div>
                </div>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default View;
