import { ITEMSTATUS } from "../../contants";
import calculateBranchOrderNumber from "../../helpers/calculateBranchOrderNumber";
import price from "../../helpers/calculatePrice";
import dummyActive from "../../helpers/dummyActiveOrder";
import { changeItemQuantityRedux, findActiveOrderIndex, isThatItemInMyOrder, pushItemToActiveOrderRedux, removeItemRedux } from "../reducers/newOrderReducer";
import { incrementOrderNumberCount } from "./orderActions";

export const getOfflineActiveOrders = (data) => {
    // window.api.createFileOffline({fileName: "log", data: {...data}})
    return {
      type: 'OFFLINE_ACTIVE_ORDERS',
      payload: data,
    };
};
  
export const getOfflineAllItems = (data) => {
  // window.api.createFileOffline({fileName: "log", data: {...data}})
  return {
    type: 'OFFLINE_ALL_ITEMS',
    payload: data,
  };
};

export const getOfflineTables = (data) => {
    // window.api.createFileOffline({fileName: "log", data: {...data}})
    return {
      type: 'OFFLINE_TABLES',
      payload: data,
    };
};

export const getOfflineAllTables = (data) => {
    // window.api.createFileOffline({fileName: "log", data: {...data}})
    return {
      type: 'OFFLINE_ALL_TABLES',
      payload: data,
    };
};

export const getOfflineItemCategories = (data) => {
    // window.api.createFileOffline({fileName: "log", data: {...data}})
    return {
      type: 'OFFLINE_ITEM_CATEGORIES',
      payload: data,
    };
};

export const getOfflineUser = (data) => {
    // window.api.createFileOffline({fileName: "log", data: {...data}})
    return {
      type: 'OFFLINE_USER',
      payload: data,
    };
};

export const getOfflineItemAvailability = (data) => {
    // window.api.createFileOffline({fileName: "log", data: {...data}})
    return {
      type: 'OFFLINE_ITEM_AVAILABILITY',
      payload: data,
    };
};

export const deleteLocalOrderOffline = (refId) => {
  return (dispatch, getState) => {
    let allOrders = getState().order.activeOrders; 
    const orderIndex = findActiveOrderIndex(allOrders, refId)

    if(orderIndex !== null){
      allOrders.splice(orderIndex, 1);
      window.api.createFileOffline({fileName: "activeOrders", data: allOrders })
    }

    return dispatch({
      type: 'OFFLINE_ACTIVE_ORDERS',
      payload: allOrders,
    });
  }
};

export const activateOrderOffline = (data, userName) => {
  // window.api.createFileOffline({fileName: "log80", data: "if action offline runnunf"})
  return (dispatch, getState) => {
    // window.api.createFileOffline({fileName: "log81", data: "if action return runnunf"})
    const branchCode = getState().user.branchCode;
    const activeOrders = getState().order.activeOrders;
    const orderNumber = getState().order.lastOrderNumber
    const branchOrderNumber = calculateBranchOrderNumber(
      branchCode,
      orderNumber
    );
    dispatch(incrementOrderNumberCount());
    const currentOrder = dummyActive(data, userName, orderNumber, branchOrderNumber)
    const allOrders = [...activeOrders, currentOrder]
    window.api.createFileOffline({fileName: "activeOrders", data: allOrders })
    // dispatch(incrementOrderNumberCount());
    // window.api.createFileOffline({fileName: "log79", data: "if action again return runnunf"})
    return dispatch({
      type: "OFFLINE_ACTIVATED_ORDER",
      payload: {activeOrders: allOrders, refId : currentOrder.refId},
    });
  };
};

export const pushItemToActiveOrderOffline = ({ item, isVariant, tableType}) => {
  return (dispatch, getState) => {
    // console.log(tableType[0]?.tableType);
    const refId = getState().order.activeOrder;
    let allOrders = getState().order.activeOrders;

    // window.api.createFileOffline({fileName: "log80", data: allOrders})

    const myItemId = item?.variantId || item?.id || item?.id;
    const activeOrder = allOrders[findActiveOrderIndex(allOrders, refId)];
    // console.log("activeOrder", activeOrder);
    // window.api.createFileOffline({fileName: "log81", data: activeOrder})


    if (!activeOrder) {
      alert("No Active Order");
      return;
    }

    if (activeOrder.isBillPrinted) {
      alert(
        "Bill modification not possible after printing. If you wish to change, do it after settlement"
      );
      return;
    }

    const currItem = isThatItemInMyOrder(activeOrder, myItemId);
    if (currItem) {

      allOrders = changeItemQuantityRedux({activeOrders: allOrders, refId, itemId: currItem.itemId, quantity: currItem.quantity + 1, tableType })

    } else {

      const currentitem = {
        ...item,
        itemId: item?.variantId || item?._id || item?.id,
        isVariant: isVariant,
        quantity: 1,

        itemTotal: 1 * price(tableType[0]?.tableType,item),
        itemStatus: ITEMSTATUS[0].key,
        itemStatusId: ITEMSTATUS[0].value,
        kotQuantity: 0,
        kotTotal: 0,
      }
      allOrders = pushItemToActiveOrderRedux({activeOrders: allOrders, refId, item: currentitem})

    }
    window.api.createFileOffline({fileName: "activeOrders", data: allOrders })

    return dispatch({
      type: 'OFFLINE_ACTIVE_ORDERS',
      payload: allOrders,
    });
  };
};

export const changeItemQuantityOffline = ({ quantity, itemId, tableType }) => {
  return (dispatch, getState) => {
    const refId = getState().order.activeOrder;
    let allOrders = getState().order.activeOrders;
    // const diff = quantity - foundItem.quantity;

    // if(foundItem.isQuantityChecked && foundItem.currentStock - quantity <= foundItem.lowerLimitQuantity){
    //   alert(`Quantiy of ${foundItem.itemName} is less.`)
    // }
    
    // if (
    //   checkIfQuantityExceeds(
    //     foundItem,
    //     diff > 0 ? foundItem.quantity + 1 : foundItem.quantity
    //   )
    // ) {
    //   return dispatch({
    //     type: "asdasd",
    //   });
    // }
    allOrders = changeItemQuantityRedux({activeOrders: allOrders, refId, itemId, quantity, tableType })

    window.api.createFileOffline({fileName: "activeOrders", data: allOrders })

    return dispatch({
      type: 'OFFLINE_ACTIVE_ORDERS',
      payload: allOrders,
    });
  };
};

export const removeItemOffline = (itemId) => {
  return (dispatch, getState) => {
    const refId = getState().order.activeOrder;
    let allOrders = getState().order.activeOrders;

    allOrders = removeItemRedux({activeOrders: allOrders, refId, itemId})

    window.api.createFileOffline({fileName: "activeOrders", data: allOrders })

    return dispatch({
      type: 'OFFLINE_ACTIVE_ORDERS',
      payload: allOrders,
    });
  };
}

export const getOfflineLastOrderNumber = (data) => {
  // window.api.createFileOffline({fileName: "log", data: {...data}})
  return {
    type: 'OFFLINE_LAST_ORDER_NUMBER',
    payload: data.lastOrderNumber,
  };
};

export const setSyncedOrders = (data) => {
  return {
    type: 'SYNECED_ORDERS',
    payload: data,
  };
};

export const setUnSyncedOrders = (data) => {
  return {
    type: 'UNSYNECED_ORDERS',
    payload: data,
  };
};

export const setSyncedProgress = (data) => {
  return {
    type: 'SYNECED_PROGRESS',
    payload: data,
  };
};