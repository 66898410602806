import React from "react";
import AddCommonAction from "../Actions/AddCommonAction";
import DeleteCommonAction from "../Actions/DeleteCommonAction";
import ModalContainer from "../ModalContainer";
import { getEntriesOptions } from "../SmartTable/functions";
import TableHeading from "../SmartTable/TableHeading";
import TableTitle from "../SmartTable/TableTitle";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import { MEASUREUNITS, SCOPES } from "../../../contants";
import { RootUrl } from "../../../redux/types";
import { deleteItemVariant } from "../../../redux/action/itemVariantActions";
import { usePermissions } from "../../PermissionGate";
import { deleteItemTopping, deleteItemToppingField } from "../../../redux/action/itemToppingAction"; 
import * as Inputs from "../Inputs";
import { useForm, Controller, FormProvider } from "react-hook-form";

const Nodata = () => (
  <td colSpan={"8"} className="text-center">
    {"No Data Available"}
  </td>
);

const ItemVariantsModal = ({ open, onClose, data, onSubmit, onSubmitTopping, onSubmitToppingField }) => {
  const { cgst, sgst } = useSelector((state) => state.user);
  const isOnlineOrderActive = usePermissions({
    scopes: [SCOPES.ONLINE_ORDERING],
  });
  const headers = [
    { title: "Name", key: "itemName" },

    { title: "Base Price", key: "itemPrice" },

    // isOnlineOrderActive && { title: "On.Price", key: "onlinePrice" },
    { title: "Check Qty.", key: "isQuantityChecked" },

    { title: "Qty.", key: "currentStock" },

    { title: "Lower Limit Qty.", key: "lowerLimitQuantity" },

    { title: "Description", key: "description", type: "textarea" },

    {
      title: "Type",
      key: "isNonVeg",
      renderRow: (row) => (row.isNonVeg ? `Non veg` : "Veg"),
      width: 100,
    },

    {
      title: "CGST",
      key: "cgst",
    },

    {
      title: "SGST",
      key: "sgst",
    },

    // { title: "isFeatured", key: "isFeatured", type: "boolean" },

    { title: "Status", key: "status", width: 118 },
  ];

  // if(isOnlineOrderActive){
  //   headers.splice(2,0, { title: "On.Price", key: "onlinePrice" });
  // }

  const dispatch = useDispatch();
  const emptyRow = {
    itemName: "",

    itemImage: undefined,

    isNonVeg: false,

    itemPrice: undefined,
    onlinePrice: undefined,

    description: undefined,

    hotKey: undefined,
    status: true,
    cgst: cgst,
    sgst: sgst,
    priceForAC: undefined,
    priceForNonAC: undefined,
    priceForGarden: undefined,
    priceForOnline: undefined,
  };
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState("asc");
  const { tables } = useSelector((state) => state.branch);
  // console.log(tables);
  const tableTypes = new Set(tables.map(x => x.tableType));
  // console.log(tableTypes);

  const handlePrice = (values, childindex) => {
    values.variants[childindex].priceForAC = values.variants[childindex].itemPrice;
    
    values.variants[childindex].priceForNonAC = values.variants[childindex].itemPrice;

    values.variants[childindex].priceForGarden = values.variants[childindex].itemPrice; 

    values.variants[childindex].priceForHut = values.variants[childindex].itemPrice;
    
    values.variants[childindex].priceForBanquetHall = values.variants[childindex].itemPrice;

    values.variants[childindex].priceForRoomService = values.variants[childindex].itemPrice; 

    values.variants[childindex].priceForOnline = values.variants[childindex].itemPrice;
    
    return values;
  }

  const handleKeyPress = (e, values, childindex) =>{
    if(e.keyCode === 9){
      return handlePrice(values, childindex);
    }
  }

  const isLoading = useSelector((state) => state.util.spinner);

  const extraObject = () => {
    let topping = {}
    // console.log(data);
    data?.toppingField?.map((field) => {
      topping = {...topping, [(field?.toppingFieldName)?.toLowerCase()]: data?.extras?.filter((item) => item.categories === field.toppingFieldName)}
  })
  return topping;
  }

  const initialValues = {
    itemId: data?._id || data?.id,
    variants: data?.variants || [],
    toppingField: data?.toppingField || [],
    ...extraObject()
  };


  const toppingHeaders = [
    { title: "Name", key: "itemName" },

    { title: "Base Price", key: "itemPrice" },

  ];

  const methods = useForm();
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    formState,
    reset,
    setValue,
    getValues,
  } = methods;

  const [formErrors, setFormErrors] = React.useState({});
  const [fileFields, setFileFields] = React.useState();
  const [otherFields, setOtherFields] = React.useState({});

  const handleOtherChange = ({ name, value }) => {
    setOtherFields({
      ...otherFields,
      [name]: value,
    });

    // console.log(otherFields);
  };
  const handleFileFieldChange = (name, file) => {
    // console.log("upcomin", file);
    setFileFields({
      [name]: file,
    });
  };
  const localSubmit = (values) => {
    onSubmitToppingField({ ...values });
  };

  const formData = [
    {
      type: "text",
      name: "toppingFieldName",
      label: "Topping Field Name",
      size: 4,

      placeholder: "Type Topping Field Name",
      required: true,
      rules: {
        required: {
          value: true,
          message: "Topping Field Name is required",
        },
      },
    },

    {
      type: "text",
      name: "limitForSelectedTopping",
      label: "Limit For Selected Topping",
      size: 4,

      placeholder: "Type Limit For Selecting Topping",
      required: true,
      rules: {
        required: {
          value: true,
          message: "Limit For Selecting Topping is required",
        },
      },
    },
    
  ];

  const ToppingEmptyRow = (category) => {
    return {itemName: "",
    itemPrice: undefined,
    categories: category}
  };

  // const initialValues = {
  //   itemId: data?._id || data?.id,
  //   toppingField: data?.toppingField || [],
  //   ...extraObject()
  // };

  const toppingsObject = (values) => {
    let extra = {}
    data?.toppingField?.map((field) => {
      extra = {...extra, 
        [(field?.toppingFieldName)?.toLowerCase()]: values[(field?.toppingFieldName)?.toLowerCase()].filter((val) => val.itemName !== "")}
    })
    // console.log(extra);
    return extra;
  }

  const onRemoveToppingField = (data) => {
    dispatch(deleteItemToppingField(data));
    onClose();
  }

  const onRemoveTopping = (d, cb) => {
    // console.log("line 52",d);
    dispatch(deleteItemTopping(d, cb));
  };

  const onRemove = (d, cb) => {
    dispatch(deleteItemVariant(d, cb));
  };
  return (
    <div>
      <ModalContainer
        open={open}
        onClose={() => {
          onClose();
          // setFormErrors();
          // reset();
        }}
        title={`${data?.itemName} variants`}
        size="xl"
        // title={`${mode} ${title}`}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            onSubmit({
              variants: values.variants.filter((val) => val.itemName !== ""),
            });
            // console.log(values);
          }}
        >
          {({ values }) => (
            <Form>
              <FieldArray name="variants">
                {({ insert, remove, push }) => (
                  <div class="row">
                    <div class="col-12">
                      <TableTitle
                        title={"Variants"}
                        endActions={[
                          () => (
                            <AddCommonAction
                              title="New Item Variant"
                              onClick={() => push(emptyRow)}
                            />
                          ),
                        ]}
                      />
                      <div class="d-flex justify-content-between align-items-center mb-4">
                        <label style={styles.paginated}>
                          Total {values.variants.length} entries
                        </label>
                      </div>
                      <div class={"table-responsive "}>
                        <table
                          id="datatable"
                          class="table table-bordered dt-responsive nowrap"
                        >
                          <TableHeading
                            data={headers || []}
                            hasActions={true}
                            onRequestSort={() => {}}
                            sortable={false}
                            selectable={false}
                            onSelectAll={(e) => {}}
                            order={order}
                          />
                          <tbody>
                            {values.variants.length === 0 && <Nodata />}
                            {values.variants.map((item, childindex) => {
                              return (
                                <>
                                <tr key={childindex}>
                                  <td>
                                    <Field
                                      disabled={isLoading}
                                      name={`variants.${childindex}.itemName`}
                                      placeholder="Enter Item Variant Name"
                                      type="text"
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      disabled={isLoading}
                                      name={`variants.${childindex}.itemPrice`}
                                      placeholder="Enter Item Price"
                                      type="number"
                                      steps="0.0"
                                      className="form-control"
                                      onKeyDown={(e) => values = handleKeyPress(e, values, childindex)}
                                    />
                                  </td>

                                  {/* {isOnlineOrderActive && (
                                    <td>
                                      <Field
                                        disabled={isLoading}
                                        name={`variants.${childindex}.onlinePrice`}
                                        placeholder="Enter Online Price"
                                        type="number"
                                        steps="0.0"
                                        className="form-control"
                                      />
                                    </td>
                                  )} */}
                                  <td>
                                    <Field
                                      disabled={isLoading}
                                      name={`variants.${childindex}.isQuantityChecked`}
                                    >
                                      {({
                                        field, // { name, value, onChange, onBlur }
                                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                        meta,
                                      }) => (
                                        <div class="custom-control custom-switch switch-primary">
                                          <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            id={`customSwitchPrimary${childindex}isQuantityChecked`}
                                            {...field}
                                            checked={field.value}
                                          />
                                          <label
                                            class="custom-control-label"
                                            for={`customSwitchPrimary${childindex}isQuantityChecked`}
                                          ></label>

                                          {meta.touched && meta.error && (
                                            <div className="error">
                                              {meta.error}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </td>

                                  <td>
                                    <Field
                                      disabled={isLoading}
                                      name={`variants.${childindex}.currentStock`}
                                      placeholder="Enter Current Quantity"
                                      type="number"
                                      steps="0.0"
                                      className="form-control"
                                      onClick={() => values = handlePrice(values, childindex)}
                                      // onKeyDown={(e) => values = handleKeyPress(e, values, childindex)}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      disabled={isLoading}
                                      name={`variants.${childindex}.lowerLimitQuantity`}
                                      placeholder="Enter Lower limit of Quantity"
                                      type="number"
                                      steps="0.0"
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      disabled={isLoading}
                                      name={`variants.${childindex}.description`}
                                      placeholder="Enter Description"
                                      type="textarea"
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      disabled={isLoading}
                                      as="select"
                                      className="form-control"
                                      name={`variants.${childindex}.isNonVeg`}
                                    >
                                      <option selected value="false">
                                        Veg
                                      </option>
                                      <option selected value="true">
                                        Non veg
                                      </option>
                                    </Field>
                                  </td>
                                  <td>
                                    <Field
                                      disabled={isLoading}
                                      type="number"
                                      steps="0.0"
                                      className="form-control"
                                      name={`variants.${childindex}.cgst`}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      disabled={isLoading}
                                      type="number"
                                      steps="0.0"
                                      className="form-control"
                                      name={`variants.${childindex}.sgst`}
                                    />
                                  </td>
                                  {/* <td>
                                    <Field
                                      disabled={isLoading}
                                      name={`variants.${childindex}.isFeatured`}
                                    >
                                      {({
                                        field, // { name, value, onChange, onBlur }
                                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                        meta,
                                      }) => (
                                        <div class="custom-control custom-switch switch-primary">
                                          <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            id={`customSwitchPrimary${childindex}`}
                                            {...field}
                                            checked={field.value}
                                          />
                                          <label
                                            class="custom-control-label"
                                            for={`customSwitchPrimary${childindex}`}
                                          ></label>

                                          {meta.touched && meta.error && (
                                            <div className="error">
                                              {meta.error}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </td> */}

                                  <td>
                                    <Field
                                      disabled={isLoading}
                                      as="select"
                                      className="form-control"
                                      name={`variants.${childindex}.status`}
                                    >
                                      <option value="true">Active</option>
                                      <option value="false">inactive</option>
                                    </Field>
                                  </td>
                                  <td
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-evenly",
                                      height: "100%",
                                    }}
                                  >
                                    <DeleteCommonAction
                                      title="New Sub Expense Type"
                                      onClick={() => {
                                        if (!isLoading) {
                                          onRemove(item, () => {
                                            remove(childindex);
                                          });
                                        }
                                      }}
                                    />
                                  </td>

                                  {/* <td>
                            <Controller
                              render={({ field }) => <input {...field} />}
                              name={`variants.${childindex}.measureUnit`}
                              control={control}
                              defaultValue={item.measureUnit} // make sure to set up defaultValue
                            />
                          </td> */}
                                  {/* {actions?.length > 0 && (
                                    <td
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-evenly",
                                        height: "100%",
                                      }}
                                    >
                                      {actions.map((Action, index) => {
                                        return (
                                          <div>
                                            <Action
                                              index={index}
                                              key={index}
                                              data={item}
                                            />
                                          </div>
                                        );
                                      })}
                                    </td>
                                  )} */}
                                </tr>
                                 <tr>
                                {tableTypes.has("AC") ?
                                 <td>AC Price: 
                                 <Field
                                   disabled={isLoading}
                                   name={`variants.${childindex}.priceForAC`}
                                   placeholder="Enter AC price"
                                   type="number"
                                   steps="0.0"
                                   className="form-control"
                                 />
                                 </td>: " "}
                                 {tableTypes.has("Non AC") ?
                                 <td>Non AC Price:
                                 <Field
                                   disabled={isLoading}
                                   name={`variants.${childindex}.priceForNonAC`}
                                   placeholder="Enter Non AC price"
                                   type="number"
                                   steps="0.0"
                                   className="form-control"
                                 />
                                 </td>: " "}
                                 {tableTypes.has("Garden") ?
                                 <td>Garden Price:
                                 <Field
                                   disabled={isLoading}
                                   name={`variants.${childindex}.priceForGarden`}
                                   placeholder="Enter Garden price"
                                   type="number"
                                   steps="0.0"
                                   className="form-control"
                                 />
                                 </td>: " "}
                                 {tableTypes.has("Hut") ? 
                                 <td>Hut Price:
                                 <Field
                                   disabled={isLoading}
                                   name={`variants.${childindex}.priceForHut`}
                                   placeholder="Enter Hut price"
                                   type="number"
                                   steps="0.0"
                                   className="form-control"
                                 />
                                 </td>:""}
                                 {tableTypes.has("Banquet Hall") ?
                                 <td>Banquet Hall Price:
                                 <Field
                                   disabled={isLoading}
                                   name={`variants.${childindex}.priceForBanquetHall`}
                                   placeholder="Enter Banquet Hall price"
                                   type="number"
                                   steps="0.0"
                                   className="form-control"
                                 />
                                 </td>:""}
                                 {tableTypes.has("Room Service") ?
                                 <td>Room Service Price:
                                 <Field
                                   disabled={isLoading}
                                   name={`variants.${childindex}.priceForRoomService`}
                                   placeholder="Enter Room Service price"
                                   type="number"
                                   steps="0.0"
                                   className="form-control"
                                 />
                                 </td>:""}
                                 <td>
                                 Online Price:
                                 <Field
                                   disabled={isLoading}
                                   name={`variants.${childindex}.priceForOnline`}
                                   placeholder="Enter Online price"
                                   type="number"
                                   steps="0.0"
                                   className="form-control"
                                 />
                                 </td>
                               </tr>
                               <div style={{height:"10px"}}></div>
                               </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </FieldArray>

              <div class="form-group mb-0">
                <button
                  type="submit"
                  disabled={isLoading}
                  class="btn btn-gradient-primary waves-effect waves-light"
                >
                  {isLoading && (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>

        <div style={{paddingTop: "20px"}}>
          <div style={styles.header}>
            <span style={styles.headerData}>Add-On Fields</span>
          </div>
          
        <FormProvider {...methods}>
        <form style={{paddingTop: "15px"}} class="form-parsley" onSubmit={handleSubmit(localSubmit)}>
            <div class="row">
              {formData.map((item, index) => {
                const MyInput = Inputs[item.type];
                // console.log(data)
                // console.log(
                //   "input value",
                //   item.hasOptions,
                //   // data[item.name],
                //   optionsData
                // );
                return (
                  (
                    <MyInput
                      {...item}
                      key={index}
                      name={item.name}
                      label={item.label}
                      placeholder={item.placeholder}
                      defaultValue={data ? data[item.name] : ""}
                      ref={register(item.rules)}
                      error={formErrors[item.name]?.message}
                      // mode={mode}
                      setValue={setValue}
                      onCustomChange={handleOtherChange}
                      onFileChange={handleFileFieldChange}
                      control={control}
                      // {...(item?.hasOptions && {
                      //   options: optionsData[item.name],
                      // })}
                      // watch={watch}
                      // readOnly={item.readOnly || mode === "View"}
                    />
                  )
                );
              })}
            </div>
            <div class="form-group mb-0">
                  <button
                    type="submit"
                    disabled={isLoading}
                    class="btn btn-gradient-primary waves-effect waves-light"
                  >
                    {isLoading && (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Submit Add-On
                  </button>
            </div>
          </form>
          </FormProvider>
        </div>

        <div style={{paddingTop: "10px"}}>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            onSubmitTopping(
              toppingsObject(values)
            );
          }}
        >
          {({ values }) => (
            <Form>
              <div style={styles.addOnContainer}>
              {values.toppingField.length !== 0 && values.toppingField.map((field) => {
                if(field === ''){
                  return <div></div>;
                }
                return(
                  
                  <FieldArray name={(field.toppingFieldName)?.toLowerCase()}>
                {({ insert, remove, push }) => (
                  <div class="row" style={{boxShadow:"0 0 10px rgba(0,0,0,0.30)", marginTop:"10px", borderRadius: "20px"}}>
                    <div class="col-12" style={{padding:"15px"}}>
                      <TableTitle
                        title={field.toppingFieldName}
                        endActions={[
                          () => (
                            [<DeleteCommonAction 
                              title={field.toppingFieldName}
                              onClick={() => onRemoveToppingField({id: values.itemId, toppingFieldName: field.toppingFieldName})}
                            />, 
                            <AddCommonAction
                              title="New Item"
                              onClick={() => push(ToppingEmptyRow(field.toppingFieldName))}
                            />]
                          ),
                        ]}
                      />
                      {/* <div class="d-flex justify-content-between align-items-center mb-4">
                        <label style={styles.paginated}>
                          Total {values.variants.length} entries
                        </label>
                      </div> */}
                      <div class={"table-responsive "}>
                        <table
                          id="datatable"
                          class="table table-bordered dt-responsive nowrap"
                        >
                          <TableHeading
                            data={toppingHeaders || []}
                            hasActions={true}
                            onRequestSort={() => {}}
                            sortable={false}
                            selectable={false}
                            onSelectAll={(e) => {}}
                            order={order}
                          />
                          <tbody>
                            {values[(field.toppingFieldName)?.toLowerCase()]?.length === 0 && <Nodata />}
                            {values[(field.toppingFieldName)?.toLowerCase()]?.map((item, childindex) => {
                              return (
                                <tr key={childindex}>
                                  <td>
                                    <Field
                                      disabled={isLoading}
                                      name={`${(field.toppingFieldName)?.toLowerCase()}.${childindex}.itemName`}
                                      placeholder={`Enter Item ${field.toppingFieldName} Name`}
                                      type="text"
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      disabled={isLoading}
                                      name={`${(field.toppingFieldName)?.toLowerCase()}.${childindex}.itemPrice`}
                                      placeholder={`Enter ${field.toppingFieldName} Price`}
                                      type="number"
                                      defaultValue="0"
                                      steps="0.0"
                                      className="form-control"
                                    />
                                  </td>

                                  <td>
                                  <DeleteCommonAction
                                      title={field.toppingFieldName}
                                      onClick={() => {
                                        if (!isLoading) {
                                          onRemoveTopping({item, restaurantId: item.restaurantId}, () => {
                                            remove(childindex);
                                          });
                                        }
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </FieldArray>
                )
              })}
            </div>
              <div class="form-group mb-0">
                <button
                  type="submit"
                  disabled={isLoading}
                  class="btn btn-gradient-primary waves-effect waves-light"
                >
                  {isLoading && (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Submit Extras
                </button>
              </div>
            </Form>
          )}
        </Formik>
        </div>
      </ModalContainer>
    </div>
  );
};

export default ItemVariantsModal;

const styles = {
  paginated: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0px 1vw",
    maxWidth: "20vw",
  },
  select: {
    margin: "0px 0.5vw",
  },
  searchGroup: {
    maxWidth: "15vw",
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "10px",
    borderBottom: "1px solid #cee1f2",
    backgroundColor: "#f1f5fa",
  },
  headerData: {
    color: "#000444",
    alignSelf: "center",
    fontWeight: "600",
    marginTop: "0",
    marginBottom: "0",
    fontSize: "1.125rem",
  },
  addOnContainer:{
    display: "flex",
    // width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-around",
    // gridTemplateColumns: "repeat(3, 450px)",
    // gridColumnGap: "30px",
    padding: "5px"
  },
};
// import React from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { showSnackBar } from "../../redux/action/snackActions";
// import SmartTable from "../../components/common/SmartTable";

// import DeleteModal from "../../components/common/Modals/DeleteModal";
// import AddCommonAction from "../../components/common/Actions/AddCommonAction";
// import EditCommonAction from "../../components/common/Actions/EditAction";
// import DeleteCommonAction from "../../components/common/Actions/DeleteCommonAction";
// import CommonAddModal from "../../components/common/Modals/ExpenseAddModal";
// import {
//   createExpense,
//   deleteExpense,
//   getAllexpenseTypes,
//   updateExpense,
//   importExpenseTypes,
//   getAllExpenses,
//   getRestaurantExpenseType,
// } from "../../redux/action/expenseActions";
// import { RootUrl } from "../../redux/types";
// import { getAllBranches } from "../../redux/action/branchActions";
// import getErrorMessage from "../../helpers/getErrorMessage";
// import CommonImportModal from "../../components/common/Modals/CommonImportModal";
// import ImportCommonAction from "../../components/common/Actions/ImportCommonAction";

// //date
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import "bootstrap-daterangepicker/daterangepicker.css";
// import moment from "moment";
// import { DATEFORMAT, dateRanges } from "../../contants";

// const SubExpensesModal = () => {
//   const { expenses: arraycat, restaurantExpenseTypes } = useSelector(
//     (state) => state.branch
//   );
//   const { role, restaurantId, branchId } = useSelector((state) => state.user);

//   const isBranchAdmin = ["branchadmin"].includes(role);

//   const isBranchUser = ["branchuser"].includes(role);

//   const PageTitle = "Expenses";

//   const [open, setOpen] = React.useState();

//   const [importOpen, setImportOpen] = React.useState();

//   const currRestaurantId = restaurantId || undefined;

//   const currBranchId = branchId;
//   const expenses = arraycat;

//   const [state, setState] = React.useState({
//     start: moment(),
//     end: moment(),
//   });
//   const { start, end } = state;

//   const handleCallback = (start, end) => {
//     // props.setValue({ start, end });
//     // onChange(setState({ start, end }));
//     setState({ start, end });
//   };

//   const dispatch = useDispatch();
//   // const Cateogrys = useSelector((state) => state.common.Cateogrys);

//   const [actionData, setActionData] = React.useState();

//   const toggleAdd = (mode) => {
//     setOpen(mode);
//     if (mode === undefined) {
//       setActionData({});
//     }
//   };

//   const handleEdit = (data) => {
//     toggleAdd("Edit");
//     setActionData(data);
//   };

//   const handleDelete = (data) => {
//     toggleAdd("Delete");
//     setActionData(data);
//   };

//   const confirmDelete = (data) => {
//     dispatch(deleteExpense({ ...actionData, role: role }))
//       .then((res) => {
//         if (res.payload.status === 200) {
//           toggleAdd();
//           dispatch(showSnackBar("Deleted succesfully"));
//           getAllData();
//         } else {
//           dispatch(
//             showSnackBar(
//               getErrorMessage(res) || "Failed to Delete Cateogry",
//               "error"
//             )
//           );
//         }
//       })
//       .catch((err) => {
//         console.log("err", err);
//         dispatch(
//           showSnackBar(
//             getErrorMessage(err) || "Failed to Delete Cateogry",
//             "error"
//           )
//         );
//       });
//   };

//   const getAllData = (selectedBranch) => {
//     if (isBranchAdmin || isBranchUser) {
//       dispatch(getRestaurantExpenseType(restaurantId));
//       dispatch(getAllExpenses(state));
//     }
//   };

//   console.log("restaurantExpenseTypes", restaurantExpenseTypes);

//   const onAdd = (data) => {
//     if (open === "Add") {
//       dispatch(
//         createExpense({
//           ...data,
//           role: role,
//           // branchId: branchId || data.branchId,

//           ...(currRestaurantId && { restaurantId: restaurantId }),
//           ...(currBranchId && { branchId: currBranchId }),
//         })
//       )
//         .then((res) => {
//           if (res.payload.status === 200) {
//             toggleAdd();
//             dispatch(showSnackBar("Expense created successfully"));
//             getAllData();
//           } else {
//             dispatch(
//               showSnackBar(
//                 getErrorMessage(res) || "Failed to Add Cateogry",
//                 "error"
//               )
//             );
//           }
//         })
//         .catch((err) => {
//           dispatch(
//             showSnackBar(
//               getErrorMessage(err) || "Failed to Add Cateogry",
//               "error"
//             )
//           );
//         });
//     }
//     if (open === "Edit") {
//       dispatch(
//         updateExpense({
//           ...actionData,
//           ...data,
//           role: role,
//         })
//       )
//         .then((res) => {
//           if (res.payload.status === 200) {
//             dispatch(showSnackBar("Cateogry Updated Successfully", "success"));
//             getAllData();

//             toggleAdd();
//           } else {
//             dispatch(
//               showSnackBar(
//                 getErrorMessage(res) || "Failed to Update Cateogry",
//                 "error"
//               )
//             );
//           }
//         })
//         .catch((err) => {
//           console.log("err", err);
//           dispatch(
//             showSnackBar(
//               getErrorMessage(err) || "Failed to Update Cateogry",
//               "error"
//             )
//           );
//         });
//     }
//   };

//   const AddAction = () => {
//     return (
//       <AddCommonAction onClick={() => toggleAdd("Add")} title={PageTitle} />
//     );
//   };

//   const EditAction = (action) => (
//     <EditCommonAction onClick={() => handleEdit(action.data)} />
//   );

//   const DeleteAction = (action) => (
//     <DeleteCommonAction onClick={() => handleDelete(action.data)} />
//   );

//   const branchtableheaders = [
//     { title: "Expense Title", key: "expenseTitle" },
//     { title: "Expense Type", key: "expenseType" },
//     { title: "Quantity", key: "quantity" },

//     { title: "Expense Price", key: "expensePrice", isCurrency: true },
//   ];
//   const headers = branchtableheaders;

//   const defaultValues = {
//     restaurantId: restaurantId,
//   };

//   const DatePicker = (action) => (
//     <div class="">
//       <DateRangePicker
//         initialSettings={{
//           startDate: start.toDate(),
//           endDate: end.toDate(),

//           locale: {
//             format: DATEFORMAT,
//           },
//           maxDate: new Date(),

//           ranges: dateRanges,
//         }}
//         onCallback={handleCallback}
//       >
//         <input type="text" class="form-control" />
//       </DateRangePicker>
//     </div>
//   );

//   const headerComponents = {
//     branchadmin: [DatePicker],
//   };
//   React.useEffect(() => {
//     getAllData();
//   }, [state]);

//   return (
//     <>
//       <div class="page-content-tab">
//         <CommonAddModal
//           title={PageTitle}
//           open={open === "Add" || open === "Edit"}
//           onClose={() => toggleAdd()}
//           mode={open}
//           onSubmit={(e) => onAdd(e)}
//           data={actionData}
//           restaurantExpenseTypes={restaurantExpenseTypes}
//           defaultValue={defaultValues}
//         />
//         <DeleteModal
//           size="md"
//           open={open === "Delete"}
//           title={actionData?.name}
//           onClose={() => toggleAdd()}
//           onConfirm={() => confirmDelete()}
//         />
//         <SmartTable
//           title={PageTitle}
//           headerComponents={headerComponents[role]}
//           headActions={[AddAction]}
//           actions={[EditAction, DeleteAction]}
//           tableData={expenses}
//           headers={headers}
//           sortable={true}
//           paginated={true}
//           searchByLabel={"Expense Title"}
//           searchByField={"expenseTitle"}
//           rowsPerPage={5}
//         />
//       </div>
//     </>
//   );
// };

// export default SubExpensesModal;
