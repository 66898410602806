import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import Login from "../pages/Login";
import ProtectedRoute from "./ProtectedRoute";
import UtilComponent from "./UtilComponent";
import checkIfAppReady from "../helpers/checkIfAppReady";
import LoadingFullPage from "../components/common/Loading/LoadingFullPage";
import DashBoardRoutes from "./DashBoardRoutes";
import ExpiredSubscription from "../pages/ExpiredSubscription";
import ForgotPassword from "../pages/ForgotPassword";
import { updateNetworkStatus } from "../redux/action/networkStatusAction";
import { getOfflineUser } from "../redux/action/offlineDataActions";
import getoffiledata from "../helpers/readOfflineData";

const HomeRoutes = () => {
  const ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
  };
  const ready = checkIfAppReady();

  const dispatch = useDispatch();
  const {isOnline, isAppOffline} = useSelector((state) => state.networkStatus);

  React.useEffect(async () => {
    const handleOnline = () => {
      dispatch(updateNetworkStatus(true));
    };

    if(window?.api?.isElectron && (isAppOffline || !isOnline)){
      dispatch(getOfflineUser(await getoffiledata("user")))
    }

    const handleOffline = () => {
      dispatch(updateNetworkStatus(false));
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [dispatch]);

  return ready ? (
    <React.Fragment>
      <UtilComponent />
      <Route component={ScrollToTop} />
      <Route path="/expired" exact component={ExpiredSubscription} />

      <ProtectedRoute path="/" component={DashBoardRoutes} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgotpassword" component={ForgotPassword} />
    </React.Fragment>
  ) : (
    <LoadingFullPage />
  );
};
export default HomeRoutes;
