import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import { mobileRegex } from "../../../helpers/regex";
import { useSelector } from "react-redux";
import './suggestion.css';
import moment from "moment";
const OrderConfirmModal = ({
  open,
  text,
  onConfirm,
  onCancel,
  enableRemarks,
  customerName,
  customerMobile,
  hideAllInputs,
}) => {
  const { register, handleSubmit, watch, errors, setValue } = useForm();
  const onSubmit = (data) => {
    onConfirm(data);
    console.log(data);
  };

  // console.log(
  //   "rendere",
  //   open,
  //   text,
  //   onConfirm,
  //   onCancel,
  //   enableRemarks,
  //   customerName,
  //   customerMobile,
  //   hideAllInputs
  // );
  // const [state, setState] = React.useState({
  //   customerName: "Jamna",
  //   customerMobile: "89888888888",
  //   remarks: undefined,
  // });
  const [state, setState] = React.useState({
    customerName: customerName,
    customerMobile: customerMobile,
    remarks: undefined,
  });

  const [display, setDisplay] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const { customerData } = useSelector((state) => state.branch);
  const { enableCustomer, enableRemark } = useSelector((state) => state.util);
  const [numberMatch, setnumberMatch] = React.useState([]);
  const wraperRef = React.useRef(null);
  
  const endDate = moment();
  const startDate = moment();
  startDate.subtract('1', 'years');
  
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    // console.log(previousOrders);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const SuggestionElement = ((phone) => {
    let i;
    const number = [];
    // console.log(length)
    for(i = 0; i < count; i++){
      number.push(<strong style={{ fontWeight: "bold", }}>{phone[i]}</strong>)
    }
    for(i = count; i < 10; i++){
      number.push(<span>{phone[i]}</span>)
    }

    return(number);
  });

  const handleClickOutside = (e) => {
    const {current: wrap} = wraperRef;
    if(wrap && !wrap.contains(e.target)){
      setDisplay(false);
    }
  }

  const searchNumber = (text) => {

    const regex = new RegExp(`^${text}.*$`, "i");
    let matches = customerData.filter((data) => {
      return data?.customerMobile?.match(regex);
    })
    if(matches){
      setDisplay(true);
    }
    const mobileNumbers =
      matches.map(x => JSON.stringify(x))
    .map(JSON.parse);
    setnumberMatch(mobileNumbers);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleNumber = (e) => {
    setCount(e.target.value.length);
    searchNumber(e.target.value);
  };

  const handleClick = (customer) => {
    // console.log(customer);
    setValue("customerMobile", customer.customerMobile);
    setValue("customerName", customer.customerName);
    setDisplay(false);
  }

  console.log("errors", errors);
  return (
    <SweetAlert
      info
      placeHolder="Customer Mobile Number"
      showCancel={false}
      showConfirm={false}
      confirmBtnText="Confirm"
      confirmBtnBsStyle="success"
      title="Confirm Order?"
      // onConfirm={() => onConfirm(state)}
      onCancel={() => onCancel()}
      focusCancelBtn
    >
      {text}
      <form  ref={wraperRef} onSubmit={handleSubmit(onSubmit)}>
        {enableCustomer &&
          <input
            name="customerMobile"
            type="text"
            className="form-control mt-3 "
            style={{
              borderColor: "gray",
            }}
            ref={register({
              pattern: {
                value: mobileRegex,
                message: "Invalid mobile Number",
              },
            })}
            defaultValue={customerMobile}
            autocomplete="off"
            // onChange={(e) => handleChange(e)}

            // value={state.customerMobile}
            placeholder="Customer Mobile Number"
            onChange={(e) => handleNumber(e)}
          />
        }
          
          {/* {console.log(numberMatch)} */}
          {display && (<div style={{display : display ? "block" : "none", backgroundColor: "transparent", zIndex: 0}}>
            {
              numberMatch.map((item, index) => {
                return (
                  <div style = {{width: "100%", fontSize: 12, padding: "5px 10px"}} 
                      className="d-flex justify-content-start suggestion" onClick={() => handleClick(item)} >
                    {/* {console.log(item)} */}
                    {SuggestionElement(item.customerMobile)}
                  </div>
                );
              })
            }
          </div>)}
        {errors.customerMobile && (
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-start mt-2"
          >
            <span style={{ fontSize: 12, color: "red", textAlign: "left" }}>
              {errors.customerMobile.message}
            </span>
          </div>
        )}

        {enableCustomer && 
          <input
            type="text"
            name="customerName"
            className="form-control mt-3 "
            style={{
              borderColor: "gray",
            }}
            placeholder="Customer Name"
            ref={register}
            defaultValue={customerName}

            // onChange={(e) => handleChange(e)}
          />
        }
        {errors.customerName && <span>This field is required</span>}

        {enableRemark && enableRemarks && (
          <textarea
            name="remarks"
            rows="4"
            className="form-control mt-3 "
            style={{
              borderColor: "gray",
            }}
            ref={register}
            // onChange={(e) => handleChange(e)}

            // value={state.remarks}
            placeholder="Remarks"
          />
        )}
        <div className="d-flex align-items-center justify-content-around mt-3">
          <button type="submit" className="btn btn-primary ">
            Confirm
          </button>
          <button className="btn btn-info" onClick={() => onCancel()}>
            Cancel
          </button>
        </div>
      </form>
    </SweetAlert>
  );
};

export default React.memo(OrderConfirmModal);
