const tableApi = {
  GET_ALL_TABLES: `/api/restaurant/tables`,
  CREATE_TABLES: `/api/restaurant/tables/create`,
  UPDATE_TABLES: `/api/restaurant/tables/update`,
  DELETE_TABLES: `/api/restaurant/tables/delete`,

  GET_ALL_RESTAURANT_TABLETYPES: "/api/restaurant/tabletype/all",
  CREATE_RESTAURANT_TABLETYPE: "/api/restaurant/tabletype/create",
  UPDATE_RESTAURANT_TABLETYPE: "/api/restaurant/tabletype/update",
  DELETE_RESTAURANT_TABLETYPE: `/api/restaurant/tabletype/delete`,

  IMPORT_TABLE_TYPE: "/api/restaurant/tabletype/import",
};
export default tableApi;
