import itemToppingsApi from "../api/itemToppingsApi";
import { itemToppingTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";

export const createItemTopping = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Item Variant added successfully",
      errorMessage: "Failed to add Item variant",
      cb: cb,
      errorCb: errorCb,
      type: itemToppingTypes.CREATE_ITEM_TOPPINGS,
      payload: {
        request: {
          url: itemToppingsApi.CREATE_ITEM_TOPPINGS,
          method: "post",
          data: data,
        },
      },
    });
};
export const updateItemTopping = (data, resdb, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Item Topping updated successfully",
      errorMessage: "Failed to update Item Topping",
      cb: cb,
      errorCb: errorCb,
      type: itemToppingTypes.UPDATE_ITEM_TOPPINGS,
      payload: {
        request: {
          url: itemToppingsApi.UPDATE_ITEM_TOPPINGS,
          method: "put",
          data: {data, resdb: resdb},
        },
      },
    });
};

export const deleteItemTopping = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Item Variant deleted successfully",
      errorMessage: "Failed to delete Item variant",
      cb: cb,
      errorCb: errorCb,
      type: itemToppingTypes.DELETE_ITEM_TOPPINGS,
      payload: {
        request: {
          url: itemToppingsApi.DELETE_ITEM_TOPPINGS,
          method: "delete",
          data: data,
        },
      },
    });
};

export const getAllItemTopping = (data) => {
  return {
    type: itemToppingTypes.GET_ALL_ITEM_TOPPINGS,
    payload: {
      request: {
        url: itemToppingsApi.GET_ITEM_TOPPINGS,
        method: "get",
        params: data,
      },
    },
  };
};

export const updateItemToppingField = (data, cb, errorCb) => {
  // console.log("actions ",data)
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Item Topping Field updated successfully",
      errorMessage: "Failed to update Item Topping Field",
      cb: cb,
      errorCb: errorCb,
      type: itemToppingTypes.UPDATE_ITEM_TOPPING_FIELD,
      payload: {
        request: {
          url: itemToppingsApi.UPDATE_ITEM_TOPPING_FIELD,
          method: "put",
          data: data,
        },
      },
    });
};

export const deleteItemToppingField = (data, cb, errorCb) => {
  // console.log("actions ",data)
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Item Topping Field deleted successfully",
      errorMessage: "Failed to deleted Item Topping Field",
      cb: cb,
      errorCb: errorCb,
      type: itemToppingTypes.DELETE_ITEM_TOPPING_FIELD,
      payload: {
        request: {
          url: itemToppingsApi.DELETE_ITEM_TOPPING_FIELD,
          method: "delete",
          data: data,
        },
      },
    });
};
