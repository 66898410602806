import React from "react";
import DashboardCreator from "./DashboardCreator";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getReport } from "../../redux/action/reportActions";
import reportData from "./ReportLayoutData";
import { Button, Card } from "react-bootstrap";
import { getAllBranches } from "../../redux/action/branchActions";
import { getDashboard } from "../../redux/action/dashboardActions";
import SubscriptionExpireWarning from "./SubscriptionExpireWarning";
import axios from "axios";
import { BASEURL } from "../../contants";
import { deleteTodayExpenses, deleteTodayOrders, generateItemSummaryData, generateOrderSummaryData, generateRawMaterialData, moveTodayExpensetoExpense, moveTodayOrderToOrder } from "../../redux/action/tempActions";
const Reports = () => {
  const { role, restaurantId, branchId, remainingDays } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  const { data, isLoading } = useSelector((state) => state.dashboard);

  console.log(role)
  const CurrentReport = reportData[role];
  const CurrentReportData = CurrentReport?.dashboardData;
  // const dataVariable = CurrentReportData.dataVariable || "data";
  const handleClick = () => {
    // const response = await axios.get(`${BASEURL}api/restaurant/tempsummary/generateDailyRawMaterail`);
    dispatch(generateRawMaterialData()).then((data) => alert(data.payload.data.message)).catch((data) => alert(data.payload.data?.message || 'Data is not created.'));
  };

  const handleClick1 = () => {
    dispatch(generateOrderSummaryData()).then((data) => alert(data.payload.data.message)).catch((data) => alert(data.payload.data?.message || 'Data is not created.'));
  }

  const handleClick2 = () => {
    dispatch(generateItemSummaryData()).then((data) => alert(data.payload.data.message)).catch((data) => alert(data.payload.data?.message || 'Data is not created.'));
  }

  const handleClick3 = () => {
    dispatch(moveTodayOrderToOrder()).then((data) => alert(data.payload.data.message)).catch((data) => alert(data.payload.data?.message || 'Data is not moved.'));
  }

  const handleClick4 = () => {
    dispatch(moveTodayExpensetoExpense()).then((data) => alert(data.payload.data.message)).catch((data) => alert(data.payload.data?.message || 'Data is not moved.'));
  }

  const handleClick5 = () => {
    dispatch(deleteTodayOrders()).then((data) => alert(data.payload.data.message)).catch((data) => alert(data.payload.data?.message || 'Data is not deleted.'));
  }

  const handleClick6 = () => {
    dispatch(deleteTodayExpenses()).then((data) => alert(data.payload.data.message)).catch((data) => alert(data.payload.data?.message || 'Data is not deleted.'));
  }

  const intialFunction = () => {
    dispatch(
      getDashboard({
        role: role,
        restaurantId,
        branchId,
      })
    );
  };

  return (
    <div>
      {remainingDays && (
        <SubscriptionExpireWarning remainingDays={remainingDays} />
      )}
      {CurrentReportData ? (
        <DashboardCreator
          reportInfo={CurrentReportData}
          reportData={data}
          initialEffectFunction={() => intialFunction()}
        />
      ) : (
        <Card>
          <Card.Body>No Data Avaialble</Card.Body>
        </Card>
      )}
      {role === 'superadmin' && (
        <Button onClick={handleClick}>Generate Raw Material</Button>
      )}
      {role === 'superadmin' && (
        <Button style={{"marginLeft": "5px"}} onClick={handleClick1}>Generate Order Summary</Button>
      )}
      {role === 'superadmin' && (
        <Button style={{"marginLeft": "5px"}} onClick={handleClick2}>Generate Item Summary</Button>
      )}
      {role === 'superadmin' && (
        <Button style={{"marginLeft": "5px"}} onClick={handleClick3}>Move Today Order</Button>
      )}
      {role === 'superadmin' && (
        <Button style={{"marginLeft": "5px"}} onClick={handleClick4}>Move Today Expense</Button>
      )}
      {role === 'superadmin' && (
        <Button style={{"marginLeft": "5px"}} onClick={handleClick5}>Delete Today Order</Button>
      )}
      {role === 'superadmin' && (
        <Button style={{"marginLeft": "5px"}} onClick={handleClick6}>Delete Today Expense</Button>
      )}
    </div>
  );
};

export default Reports;
