import { UPDATE_NETWORK_STATUS, UPDATE_APP_OFFLINE_STATUS } from "../types";

export const updateNetworkStatus = (isOnline) => ({
  type: UPDATE_NETWORK_STATUS,
  payload: isOnline,
});

export const updateAppStatus = (value) => ({
  type: UPDATE_APP_OFFLINE_STATUS,
  payload: value,
});