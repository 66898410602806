import itemAvailabilityApi from "../api/itemAvailability";
import { itemAvailabilityTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";


export const getAllItemAvailability = () => {
    return {
      type: itemAvailabilityTypes.GET_ALL_ITEM_AVAILABILITY,
      payload: {
        request: {
          url: itemAvailabilityApi.GET_ALL_ITEM_AVAILABILITY,
          method: "get",
        },
      },
    };
  };

  export const createItemAvailability = (data) => {
    return {
        type: itemAvailabilityTypes.CREATE_ITEM__AVAILABILITY,
        payload: {
          request: {
            url: itemAvailabilityApi.CREATE_ITEM__AVAILABILITY,
            method: "post",
            data: data,
          },
        },
      }
  };

  export const updateItemAvailability = (data) => {
    return {
      type: itemAvailabilityTypes.UPDATE_ITEM_AVAILABILITY,
      payload: {
        request: {
          url: itemAvailabilityApi.UPDATE_ITEM_AVAILABILITY,
          method: "PUT",
          data: data,
        },
      },
    };
  };

  export const deleteItemAvailability = (id) => {
    console.log(id)
    return {
      type: itemAvailabilityTypes.DELETE_ITEM_AVAILABILITY,
      payload: {
        request: {
          url: itemAvailabilityApi.DELETE_ITEM_AVAILABILITY,
          method: "delete",
          params:{
            id: id
          }
        },
      },
    };
  };