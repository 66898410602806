import React from "react";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PermissionsGate from "../../components/PermissionGate";
import { SCOPES } from "../../contants";
import { togglePrintSetting } from "../../redux/action/userActions";

const printerSizeOptions = [
  { label: `Thermal Printer (3" - 80mm)`, value: "termal" },
  { label: "A5", value: "a5" },
  { label: "A4", value: "a4" },
];

const PrintSetting = () => {
  const dispatch = useDispatch();

  const handleChangeCheckBox = (name, value) => {
    if (name === "enablePrinting") {
      dispatch(
        togglePrintSetting({
          enablePrinting: value,
          enableLogo: value,
          enableAddress: value,
          enableGSTNumber: value,
          enableCustomer: value,
          enableRemark: value,
          enableBranchName: value,
          enableFssaiNumber: value,
        })
      );
    } else {
      dispatch(
        togglePrintSetting({
          [name]: value,
        })
      );
    }
  };

  const handleChangeSelectInput = (name, value) => {};

  const buttonStyle = (name) => {
    switch (name) {
      case "enableLogo":
      case "enableAddress":
      case "enableBranchName":
      case "enableGSTNumber":
      case "enableCustomer":
      case "enableRemark":
      case "enableFssaiNumber":
        return { marginLeft: "10px" };

      default:
        return {};
    }
  };

  const {
    enablePrinting,
    enableBranchName,
    enableKOT,
    enableKDS,
    enableLogo,
    enableAddress,
    enableGSTNumber,
    enableCustomer,
    enableRemark,
    enableFssaiNumber,
  } = useSelector((state) => state.util);

  const renderCheckboxInput = ({ name, label, onChange, value }) => {
    return (
      <div
        class="custom-control custom-switch switch-primary"
        style={buttonStyle(name)}
      >
        <input
          type="checkbox"
          class="custom-control-input"
          id={`customSwitchPrimary${name}`}
          checked={value}
          onChange={(e) => {
            const checked = e.target.checked;
            onChange(name, checked);
          }}
        />
        <label class="custom-control-label" for={`customSwitchPrimary${name}`}>
          {label}
        </label>
      </div>
    );
  };

  const renderSelectInput = ({
    className,
    name,
    label,
    onChange,
    value,
    options,
  }) => {
    return (
      <Form.Group controlId="exampleForm.ControlSelect1" className={className}>
        <Form.Label>{label}</Form.Label>
        <Form.Control as="select" name={name} onChange={onChange} value={value}>
          {options?.map((option) => (
            <option value={option?.value}>{option.label}</option>
          ))}
          {/* <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option> */}
        </Form.Control>
      </Form.Group>
    );
  };

  return (
    <div>
      <Card>
        <Card.Header class="card-header bg-primary">
          <h5 class="text-white"> Setting</h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={4}>
              <label>KOT</label>
              {renderCheckboxInput({
                name: "enableKOT",
                value: enableKOT,
                onChange: (name, value) => handleChangeCheckBox(name, value),
                label: "Enable KOT",
              })}
              {renderSelectInput({
                className: "mt-2",
                name: "printers",
                value: null,
                onChange: (name, value) => handleChangeSelectInput(name, value),
                label: "Select Printer",
              })}
            </Col>
            <Col md={4}>
              <label>Kitchen Display System</label>
              <PermissionsGate scopes={[SCOPES.KITCHEN_DISPLAY_SYSTEM]}>
                {renderCheckboxInput({
                  name: "enableKDS",
                  value: enableKDS,
                  onChange: (name, value) => handleChangeCheckBox(name, value),
                  label: "Enable Kitchen Display System",
                })}
              </PermissionsGate>
            </Col>
            <Col md={4}>
              <label>Bill Printing</label>
              {renderCheckboxInput({
                name: "enablePrinting",
                value: enablePrinting,
                onChange: (name, value) => handleChangeCheckBox(name, value),
                label: " Enable Bill Printing",
              })}

              {renderCheckboxInput({
                name: "enableLogo",
                value: enableLogo,
                onChange: (name, value) => handleChangeCheckBox(name, value),
                label: "Enable Logo Printing",
              })}
              {renderCheckboxInput({
                name: "enableBranchName",
                value: enableBranchName,
                onChange: (name, value) => handleChangeCheckBox(name, value),
                label: "Enable Branch Name Printing",
              })}

              {renderCheckboxInput({
                name: "enableAddress",
                value: enableAddress,
                onChange: (name, value) => handleChangeCheckBox(name, value),
                label: "Enable Branch Address Printing",
              })}
              {renderCheckboxInput({
                name: "enableGSTNumber",
                value: enableGSTNumber,
                onChange: (name, value) => handleChangeCheckBox(name, value),
                label: "Enable GST Number Printing",
              })}
              {renderCheckboxInput({
                name: "enableCustomer",
                value: enableCustomer,
                onChange: (name, value) => handleChangeCheckBox(name, value),
                label: "Enable Cutomer Info Printing",
              })}
              {renderCheckboxInput({
                name: "enableRemark",
                value: enableRemark,
                onChange: (name, value) => handleChangeCheckBox(name, value),
                label: "Enable Remark Info Printing",
              })}
              {renderCheckboxInput({
                name: "enableFssaiNumber",
                value: enableFssaiNumber,
                onChange: (name, value) => handleChangeCheckBox(name, value),
                label: "Enable FSSAI Number Printing",
              })}
              {renderSelectInput({
                className: "mt-2",
                name: "printers",
                value: null,
                onChange: (name, value) => handleChangeSelectInput(name, value),
                label: "Select Printer",
              })}

              {renderSelectInput({
                className: "mt-2",
                name: "",
                value: null,
                onChange: (name, value) => handleChangeSelectInput(name, value),
                label: "Select Paper Size",
                options: printerSizeOptions,
              })}
            </Col>
          </Row>
          {/* {renderCheckboxInput({
            name: "enableKOT",
            value: enableKOT,
            onChange: (name, value) => handleChangeCheckBox(name, value),
            label: "Enable KOT",
          })} */}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PrintSetting;
