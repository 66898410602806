import React from "react";
import { ProgressBar, Container, Card, Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { syncOrders } from "../../redux/action/orderActions";
import { setSyncedOrders, setSyncedProgress, setUnSyncedOrders } from "../../redux/action/offlineDataActions";

const SyncOrderSettings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [files, setFiles] = React.useState([]); 
  const { syncedOrders, unSyncedOrders, syncingProgress } = useSelector((state) => state.order);
  const [message, setMessage] = React.useState('');

  React.useEffect(() => {
    if(syncingProgress >= 100){
      window.api.deleteFilesInBatches({folderName: 'order',files: files, index: syncedOrders})
      dispatch(setSyncedOrders(null))
      dispatch(setSyncedProgress(0))
      // unSyncedOrders.length > 0 ? setMessage('Some oders are not synced') : setMessage('oders are synced')
    }
  },[syncingProgress, message])

  const syncLogic = (data) => {
    for(let i = 0; i < data.length; i = i+5){
      dispatch(syncOrders({restaurantId: user.restaurantId, data: data.slice(i, i+5), index: i}))
      .then(() => {
        dispatch(setSyncedOrders(i));
        dispatch(setSyncedProgress((((i + 5) / data.length) * 100).toFixed(2)));
        if(i >= data.length - 5){
          setMessage('oders are synced')
          alert("Orders Synced Successfully.")
        }else{
          setMessage('orders are syncing.')
        }
        
      } )
      .catch((err) => {
        if (err) {
          dispatch(setUnSyncedOrders(err.meta.previousAction.payload.request.data.index))
          dispatch(setSyncedProgress((((i + 5) / data.length) * 100).toFixed(2)));
          if(i >= data.length - 5){
              alert("Some Orders Failed to Sync.")
              setMessage('Some oders are not synced')
          }else{
            setMessage('orders are syncing.')
          }
        }
      })
  }
}

  const handleSync = async () => {
    const data = await window.api.readFilesFromFolder("order")
    setFiles(data.files);
    if(data.result.length > 0){
      syncLogic(data.result)
    }
    else{
      setMessage('No data to Sync.')
      alert("No data to Sync.")
    }
    
  }

  return (
    <Card>
      <Card.Header class="card-header bg-primary">
        <h5 class="text-white">Sync Offline Orders</h5>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={2}>
            <Button
              variant="primary"
              block
              style={{ backgroundColor: "rgb(240, 88, 60)", border: "none" }}
              onClick={() => handleSync()}
            >
              Sync Offline Orders
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "5px" }}>
            <Col md={10}>
                <h4>Synced Data</h4>
                <ProgressBar animated 
                striped 
                variant="success" now={syncingProgress} label={syncingProgress > 100 ? "100%" : `${syncingProgress}%`} />
            </Col>
        </Row>
        {message !== '' && 
          <Row style={{ marginTop: "3px" }}>
            <Col md={2}><h5>{message}</h5></Col>
          </Row>}
      </Card.Body>
    </Card>
  );
};

export default SyncOrderSettings;
