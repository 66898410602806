import React from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import InputContainer from "./InputContainer";
import { Controller, useController, useForm } from "react-hook-form";
import {
  DATEFORMAT,
  dateRanges,
  DATETIMEFORMAT,
  TIMEONLYFORMAT,
} from "../../../contants";
import DatetimePciker from "react-datetime";
import "react-datetime/css/react-datetime.css";
import style from "./time.css";

const Time = (props) => {
  const {
    label,
    name,
    placeholder,
    multiline,
    rows,
    error,
    size,
    options,
    onCustomChange,
    value,
    noPadding,
    defaultValue,
    control,
    isValidDate,
    readOnly,
  } = props;
  return (
    <InputContainer
      {...props}
      noPadding={noPadding}
      size={size}
      label={label}
      error={error}
    >
      <Controller
        control={control}
        name={name}
        rules={props.rules}
        render={(props) => {
          // console.log("Time value", props);
          return (
            <DatetimePciker 
              // initialValue={defaultValue}
              className={style}
              onChange={(date) => props.onChange(date?.toDate()) }
              value={new Date(props.value)}
              dateFormat={false}
              timeFormat={TIMEONLYFORMAT}
              inputProps={{ readOnly: true, disabled: readOnly }}
              timeConstraints={{
                hours: { min: 0, max: 24 },
                minutes: { min: 0, max: 59 },
              }}
              {...options}
            />
          );
        }}
        defaultValue={defaultValue}
      />
    </InputContainer>
  );
};

export default Time;