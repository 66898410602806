import { useState, useEffect } from "react";

const useResponsive = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);

  const updateDeviceType = () => {
    const width = window.innerWidth;

    setIsMobile(width <= 768);
    setIsTablet(width > 768 && width <= 1024);
    setIsLaptop(width > 1024);
  };

  useEffect(() => {
    updateDeviceType();
    window.addEventListener("resize", updateDeviceType);

    return () => window.removeEventListener("resize", updateDeviceType);
  }, []);

  return { isMobile, isTablet, isLaptop };
};

export default useResponsive;
