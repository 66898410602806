import React from "react";
import OrderTypeSelector from "./OrderTypeSelector";
import { useDispatch, useSelector } from "react-redux";
import {
  activateOrder,
  activateTable,
  setActiveOrder,
  syncOrders,
} from "../../../redux/action/orderActions";
import ButtonOrderSelector from "./ButtonOrderSelector";
import TableStatusInfo from "./TableStatusInfo";
import TableTypeSelector from "./TableTypeSelector";
import { ProgressBar, Container, Col, Row } from "react-bootstrap";
import ShortCutList from "./ShortCutList";
import { TYPESOFORDERS } from "../../../contants";
import calculateBranchOrderNumber from "../../../helpers/calculateBranchOrderNumber";
import { activateOrderOffline, getOfflineLastOrderNumber } from "../../../redux/action/offlineDataActions";
import { RootUrl } from "../../../redux/types";
import getoffiledata from "../../../helpers/readOfflineData";
const TopPortion = () => {
  const dispatch = useDispatch();
  const username = useSelector((state) => state.user.name);
  const [tableFilterId, setTableFilterId] = React.useState("all");

  const {
    activeOrders,
    lastOrderNumber,
    branchCode,
    allItems,
    allTables,
    itemCategories
  } = useSelector((state) => state.order);
  const { tables } = useSelector((state) => state.branch);
  const { itemAvailability } = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);
  const {isOnline, isAppOffline} = useSelector((state) => state.networkStatus);
  const isElectron = window?.api?.isElectron;

  const checkactive = (tableNumber) => {
    let myIndex = activeOrders.find((table) => {
      return tableNumber === table.tableNumber;
    });

    if (myIndex) {
      return myIndex;
    } else {
      return false;
    }
  };
  const handleTableNumberClick = async (data, index) => {
    if (checkactive(data.tableNumber)) {
      dispatch(setActiveOrder(checkactive(data.tableNumber).refId));
    } else {
      if((isAppOffline || !isOnline) && isElectron){
        dispatch(getOfflineLastOrderNumber(await getoffiledata("orderCount")))
        dispatch(activateOrderOffline({...data,},username))
      }else{
        dispatch(activateOrder({...data,},username))
      }
    }
  };

  const handleDownload = () => {
    window.api.createFileOffline({fileName: "activeOrders", data: activeOrders })
    window.api.createFileOffline({fileName: "allTables", data: allTables })
    window.api.createFileOffline({fileName: "branchCode", data: branchCode })
    window.api.createFileOffline({fileName: "allItems", data: allItems, RootUrl: RootUrl })
    window.api.createFileOffline({fileName: "tables", data: tables })
    window.api.createFileOffline({fileName: "itemAvailability", data: itemAvailability })
    window.api.createFileOffline({fileName: "itemCategories", data: itemCategories })
    window.api.createFileOffline({fileName: "user", data: user })
    window.api.createFileOffline({fileName: "orderCount", data: {lastOrderNumber : lastOrderNumber} })
    alert("Offline Data Is Created.");
  }


  const handleTableTypeFilter = (typeId) => {
    setTableFilterId(typeId);
  };

  const handleOtherOrderClick = (data) => {
    dispatch(setActiveOrder(data.refId));
  };

  const getFilteredTables = () => {
    if (tableFilterId === "all") {
      return allTables;
    } else {
      return allTables.filter((tab) => tab.tableTypeId === tableFilterId);
    }
  };

  const getParcelOrders = () => {
    return activeOrders.filter(
      (order) => order.orderTypeId === TYPESOFORDERS[1].value
    );
  };

  const getHomeDeliveryOrders = () => {
    return activeOrders.filter(
      (order) => order.orderTypeId === TYPESOFORDERS[2].value
    );
  };

  const RowRender = ({ children }) => <Row class="pb-0 mb-0 ">{children}</Row>;
  const ColRender = ({ children, lg }) => (
    <Col lg={lg} class="pb-0 mb-0 ">
      {children}
    </Col>
  );

  const getTableTypes = () => {
    const key = "tableType";

    const arrayUniqueByKey = [
      ...new Map(allTables.map((item) => [item[key], item])).values(),
    ];

    return arrayUniqueByKey;
  };
  return (
    <div class="card mb-0" style={{ width: "100%" }}>
      <div class="card-body pb-2 pt-2 mb-0">
        <RowRender>
          <div class="col-lg-3 p-0 pr-2">
            <OrderTypeSelector />
            <ShortCutList handleDownload = {handleDownload}/>
          </div>
          <ColRender lg={9}>
            <RowRender>
              <ColRender lg={3}>
                <RowRender>
                  <TableTypeSelector
                    selected={tableFilterId}
                    setSelected={handleTableTypeFilter}
                    tablesTypes={getTableTypes().map((data) => {
                      return {
                        tableTypeName: data.tableType,
                        tableTypeId: data.tableTypeId,
                      };
                    })}
                  />
                </RowRender>
                <RowRender>
                  <TableStatusInfo />
                </RowRender>
              </ColRender>

              <ColRender lg={9}>
                <ButtonOrderSelector
                  tables={getFilteredTables()}
                  parcels={getParcelOrders()}
                  homeDeliveries={getHomeDeliveryOrders()}
                  handleTableNumberClick={handleTableNumberClick}
                  handleOtherOrderClick={handleOtherOrderClick}
                />
              </ColRender>
            </RowRender>
          </ColRender>
        </RowRender>
      </div>
    </div>
  );
};

export default TopPortion;
