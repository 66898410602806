export const orderApi = {
  CREATE_ORDER: `/api/restaurant/orders/create`,
  UPDATE_ORDER: `/api/restaurant/orders/update`,
  DELETE_ORDER: `/api/restaurant/orders/delete`,

  EDIT_ORDER: `/api/restaurant/orders/edit`,

  GET_PREVIOS_ORDERS: `/api/restaurant/orders`,

  SYNC_ORDERS: `/api/restaurant/orders/syncorders`,
};
