import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sendOtpForActionLockSetting, verifyOtpForActionUnlockSetting, actionLockSetting } from "../../redux/action/userActions";

const ActionLockSettings = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState();
  const [otp, setOtp] = React.useState();

  const {
    restaurantId,
    branchId,
    mobile,
  } = useSelector((state) => state.user);

  const handleChange = (e) => {
    setOtp(e.target.value)
  };

  const clearLoading = () => setLoading();

  const lockActionSetting = () => {
    setLoading("locking")
    dispatch(actionLockSetting({mobile}, () => clearLoading(), () => clearLoading()))
  }

  const sendOtp = () => {
    setLoading("sending")
    dispatch(sendOtpForActionLockSetting({mobile, restaurantId, branchId}, () => clearLoading(), () => clearLoading())) 
};

const unlockActionSetting = () => {
  setLoading("unlocking")
  dispatch(verifyOtpForActionUnlockSetting({mobile,otp: otp}, () => clearLoading(), () => clearLoading()))
}
  
  return (
    <Card>
      <Card.Header class="card-header bg-primary">
        <h5 class="text-white">Action Lock Setting</h5>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={2}>
            <Button
              variant="primary"
              block
              style={{ backgroundColor: "rgb(240, 88, 60)", border: "none" }}
              onClick={() => lockActionSetting()}
            >
              {loading === "locking" && (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Lock All Actions
            </Button>
          </Col>
          </Row>
          <Row style={{ marginTop: "5px" }}>
              <Col md={2}>
                  <Button variant="primary" block onClick={() => sendOtp()}>
                      {loading === "sending" && (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      Send OTP
                  </Button>
              </Col>
              <Col md={3}>
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    class="form-control"
                    rows={"4"}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
              </Col>
              <Col md={3}>
                <Button
                  variant="primary"
                  block
                  style={{ backgroundColor: "rgb(240, 88, 60)", border: "none" }}
                  onClick={() => unlockActionSetting()}
                >
                  {loading === "unlocking" && (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Verify / Unlock All Actions
                </Button>
              </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ActionLockSettings;
