import React from "react";
import { CURRENCY } from "../../../contants";
import price from "../../../helpers/calculatePrice";
import ModalContainer from "../ModalContainer";
import { useSelector } from "react-redux";
import SmartTable from "../SmartTable";

const styles = {
  variantsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  rowStyle: {
    flex: 3,
  },
  rowContainerStyle: {
    cursor: "cell",
  },
  addOnContainer:{
    display: "flex",
    // width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-around",
    // gridTemplateColumns: "repeat(3, 450px)",
    // gridColumnGap: "30px",
    padding: "5px"
  },
  selectedRow:{
    backgroundColor: "#506ee4",
  }
};

const VariantSelectorModal = ({
  open,
  onClose,
  variants,
  extras,
  categories,
  onVariantClick,
  onToppingSubmit,
  title,
  tableType,
}) => {
  // console.log(tableType);
  const headers = [
    { title: "Item Name", key: "itemName" },

    { title: "Price", key: "itemPrice" },
  ];

  const [selectedData, setSelectedData] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const isLoading = useSelector((state) => state.util.spinner);

  React.useEffect(() => {
    setSelectedData(extras);
  }, [extras, open]);

  const submitData = () => {
    // console.log(selectedData);
    let data = []
    let updateData = []
    categories?.map((field) => {
      data = [...data, ...selectedData[(field?.toppingFieldName)?.toLowerCase()]?.filter((item) => item.selected)]
      updateData = [...updateData, ...selectedData[(field?.toppingFieldName)?.toLowerCase()]?.map((item) => item.selected = false)]
    })
    // console.log(data);
    onToppingSubmit(data);

    setSelectedData(updateData);
    setSelectedItem(null);
    onClose();
  };

  const handleClick = (vars) => {
    setSelectedItem(vars.itemName);
    onVariantClick(vars);
  }

  const handleClose = () => {
    setSelectedItem(null);
    onClose();
  }

  return (
    <ModalContainer
      open={open}
      onClose={() => {
        handleClose();
        // setFormErrors();
        // reset();
      }}
      title={`${title} variants`}
      size="l"
      centered
      noPadding
      // title={`${mode} ${title}`}
    >
      <div style={styles.variantsContainer}>
        {variants.map((vars, varIndex) => {
          return (
            <div
              className="d-flex flex-direction-row align-items-center border p-2 w-100 variant-row"
              style={selectedItem === vars.itemName ? {...styles.rowContainerStyle, ...styles.selectedRow} : styles.rowContainerStyle}
              onClick={() => handleClick(vars)}
            >
              <div
                style={styles.rowStyle}
                className="text-primary font-weight-bold text-capitalize"
              >
                {vars.itemName}
              </div>
              <div style={{ flex: 1 }} className="text-center">
                <img
                  src={
                    vars.isNonVeg ? "/images/non-veg.png" : "/images/veg.png"
                  }
                  style={{
                    height: 20,
                    width: 20,
                  }}
                />
              </div>
              <div
                style={{ flex: 1 }}
                className="d-flex flex-direction-row justify-content-end"
              >
                <div>
                  {" "}
                  {CURRENCY} {price(tableType, vars) || vars.itemPrice}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div class="form-group mt-3 mb-3 d-flex justify-content-center">
        <button
          type="submit"
          onClick={() => handleClose()}
          class="btn btn-gradient-primary waves-effect waves-light"
        >
          OK
        </button>
      </div>

      <div>
          {categories?.length > 0 &&
          <div style={{padding: "14px", width: "100%"}}>
          <div style={styles.addOnContainer}>
          {categories?.map((category) => 
            <SmartTable
              title={category?.toppingFieldName + ' Select Any '+ category?.limitForSelectedTopping}
              tableData={extras?.[(category?.toppingFieldName)?.toLowerCase()]}
              setTableData={(data) => setSelectedData({...selectedData, [(category?.toppingFieldName)?.toLowerCase()]: data})}
              limit={parseInt(category?.limitForSelectedTopping)}
              headers={headers}
              sortable={true}
              selectable={true}
              paginated={false}
              // searchByLabel={"Item name"}
              // searchByField={"itemName"}
              rowsPerPage={5}
              paginatedPage={true}
            />
          )}
          </div>
          
          <div class="form-group mt-3 mb-3 d-flex justify-content-center">
            <button
              type="submit"
              disabled={isLoading}
              onClick={() => submitData()}
              class="btn btn-gradient-primary waves-effect waves-light"
            >
              {isLoading && (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Submit Add-Ons
            </button>
            </div>
        </div>}
        </div>
    </ModalContainer>
  );
};

export default VariantSelectorModal;
