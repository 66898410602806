import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShortCutModal from "../../../components/common/Modals/ShortCutModal";
import useFullscreenStatus from "../../../hooks/useFullscreenStatus";
import { toggleFullScreen } from "../../../redux/action/utilActions";
import Clock from "react-live-clock";
import { TIMEFORMAT, TIMEZONE } from "../../../contants";
import { updateAppStatus } from "../../../redux/action/networkStatusAction";
const ShortCutList = ({handleDownload}) => {
  const btnRef = React.useRef();
  const dispatch = useDispatch();
  const [shortCutOpen, setShortCutOpen] = React.useState(false);

  const {isOnline, isAppOffline} = useSelector((state) => state.networkStatus);

  const toggleShortCut = () => setShortCutOpen(!shortCutOpen);
  const handleFullscreen = () => dispatch(toggleFullScreen());

  const handleAppStatus = () => dispatch(updateAppStatus(!isAppOffline));

  const isElectron = window?.api?.isElectron;
  const handleClick = () => {handleDownload()}

  const shortcuts = [
    // {
    //   title: "shortcuts",
    //   icon: "mdi mdi-keyboard",
    //   onClick: () => toggleShortCut(),
    // },
    {
      title: "fullscreen",
      icon: "mdi mdi-fullscreen",
      onClick: () => handleFullscreen(),
    },
  ];
  return (
    <div class="mt-2">
      <ShortCutModal
        open={shortCutOpen}
        ref={btnRef}
        onClose={() => toggleShortCut()}
      />
      <button
        type="button"
        ref={btnRef}
        class="btn btn-outline-primary  mr-2 "
        style={{
          width: 100,
        }}
      >
        <Clock format={TIMEFORMAT} ticking={true} timezone={TIMEZONE} />
      </button>
      {shortcuts.map((data, index) => {
        return (
          <button
            type="button"
            ref={btnRef}
            class="btn btn-outline-primary btn-circle mr-2"
            onClick={() => data.onClick()}
          >
            <i class={data.icon} style={{ fontSize: 20 }}></i>
          </button>
        );
      })}
      {(!isAppOffline && isOnline) ? 
      <button
          type="button"
          class="btn btn-success btn-sm shadow-none"
        >
          <i class="mdi mdi-wifi" style={{ paddingRight: "5px" }}></i>
          Online
      </button>
      :
      <button
          type="button"
          class="btn btn-danger btn-sm shadow-none"
        >
          <i class="mdi mdi-wifi-off" style={{ paddingRight: "5px" }}></i>
          Offline
      </button>}
      {(!isAppOffline && isOnline) && isElectron && 
      <button
          type="button"
          class="btn btn-success btn-sm shadow-none"
          onClick={() => handleClick()}
        >
          <i class="mdi mdi-download" style={{ paddingRight: "5px" }}></i>
          Download Offline Data
      </button>}
      {isElectron ? (!isAppOffline && isOnline) ? 
      <button
          type="button"
          class="btn btn-success btn-sm shadow-none"
          onClick={() => handleAppStatus()}
        >
          <i class="mdi mdi-wifi" style={{ paddingRight: "5px" }}></i>
          App Online
      </button>
      :
      <button
          type="button"
          class="btn btn-danger btn-sm shadow-none"
          onClick={() => handleAppStatus()}
        >
          <i class="mdi mdi-wifi-off" style={{ paddingRight: "5px" }}></i>
          App Offline
      </button> : <div></div>}
    </div>
  );
};

export default ShortCutList;
