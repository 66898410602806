import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { SCOPES } from "../../contants";
import { SMALLLOGO } from "../../redux/types";
import PermissionGate from "../PermissionGate";
import useResponsive from "../../hooks/useResponsive";
import { useSelector } from "react-redux";

function LeftSidebar({ sidebarData, sideMenuOpen, setSideMenuOpen }) {
  const [active, setActive] = useState(0);
  const [childMenuOpen, setChildMenuOpen] = useState(false);
  const { isFullScreen } = useSelector((state) => state.util);
  const { pathname } = useLocation();
  const history = useHistory();
  const { isMobile } = useResponsive();

  const activeValue = sidebarData[active];

  useEffect(() => {
    setChildMenuOpen(activeValue?.children ? true : false);
  }, [active]);

  const handleNavigate = (link) => history.push(link);

  const handleIconClickNavigate = (data, index) => {
    setActive(index);
    if (data?.link) {
      history.push(data.link);
    } else if (data?.children) {
      history.push(data.children[0].link);
    }
  };

  const renderMenuItems = (items) =>
    items.map((item, index) => (
      <li className="nav-item" key={index}>
        <a
          className={`nav-link ${item?.link === pathname ? "active" : ""}`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleNavigate(item.link);
            setChildMenuOpen(false);
            setSideMenuOpen(false);
          }}
        >
          <span className="w-100">{item.title}</span>
          {item.children && (
            <span className="menu-arrow">
              <i className="mdi mdi-chevron-right"></i>
            </span>
          )}
        </a>
        {item?.children && (
          <ul className="nav-second-level" aria-expanded="false">
            {renderMenuItems(item.children)}
          </ul>
        )}
      </li>
    ));

  console.log(sideMenuOpen);

  return (
    <div className="leftbar-tab-menu">
      <div
        className={`main-icon-menu ${isFullScreen && "d-none"} ${
          isMobile ? (sideMenuOpen ? "" : "active") : ""
        }`}
      >
        <a
          onClick={() => handleNavigate("/")}
          className="logo logo-metrica d-block text-center"
          style={{ cursor: "pointer" }}
        >
          <img
            src={SMALLLOGO}
            style={{ borderRadius: "100%" }}
            alt="logo-small"
            className="logo-sm"
          />
        </a>
        <nav className="nav">
          {sidebarData?.map((data, index) => (
            <OverlayTrigger
              key={index}
              placement="right"
              overlay={
                <Tooltip
                  id="tooltip-disabled"
                  className={!sideMenuOpen && isMobile ? "d-none" : "d-block"}
                >
                  {data.title}
                </Tooltip>
              }
            >
              <button
                className={`nav-link ${active === index ? "active" : ""}`}
                onClick={() => {
                  handleIconClickNavigate(data, index);
                  setChildMenuOpen(!childMenuOpen);
                  setSideMenuOpen(false);
                }}
              >
                <i
                  className={`align-self-center menu-icon icon-dual ${data.icon}`}
                ></i>
              </button>
            </OverlayTrigger>
          ))}
        </nav>
      </div>

      {activeValue?.children && childMenuOpen && (
        <div className="main-menu-inner">
          <div className="menu-body slimscroll">
            <div className="main-icon-menu-pane mm-active active">
              <div className="title-box">
                <h6 className="menu-title">{activeValue.title}</h6>
              </div>
              <ul className="nav">
                {activeValue.children.map((child, index) =>
                  child.permission ? (
                    <PermissionGate
                      key={index}
                      scopes={[SCOPES.BOOKING_SYSTEM]}
                    >
                      {renderMenuItems([child])}
                    </PermissionGate>
                  ) : (
                    renderMenuItems([child])
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LeftSidebar;
