import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showSnackBar } from "../../redux/action/snackActions";

import SmartTable from "../../components/common/SmartTable";
import CommonAddModal from "../../components/common/Modals/CommonAddModal";
import DeleteModal from "../../components/common/Modals/DeleteModal";

import EditCommonAction from "../../components/common/Actions/EditAction";
import AddCommonAction from "../../components/common/Actions/AddCommonAction";
import DeleteCommonAction from "../../components/common/Actions/DeleteCommonAction";
import getErrorMessage from "../../helpers/getErrorMessage";
import { createItemAvailability, getAllItemAvailability, updateItemAvailability, deleteItemAvailability } from "../../redux/action/itemAvailability";

const PageTitle = "Item Avalibality";

const ManageItemAvailability = () => {
  const { itemAvailability } = useSelector((state) => state.common);
  const { branchId, actionLockSetting } = useSelector((state) => state.user);

  const formData = [
    {
      type: "text",
      name: "mealName",
      label: "Meal Name",
      required: true,
      rules: {
        required: {
          value: true,
          message: "Meal Name is required",
        },
      },
    },

    {
      type: "time",
      name: "mealStartTime",
      label: "Meal Start Time",
      placeholder: "Meal Start Time",
      required: true,
      rules: {
        required: {
          value: true,
          message: "Meal Start Time is required",
        },
      },
    },

    {
        type: "time",
        name: "mealEndTime",
        label: "Meal End Time",
        placeholder: "Meal End Time",
        required: true,
        rules: {
          required: {
            value: true,
            message: "Meal End Time is required",
          },
        },
      },
  ];

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState();
  const [actionData, setActionData] = React.useState();

  const toggleAdd = (mode) => {
    setOpen(mode);
    if (mode === undefined) {
      setActionData({});
    }
  };

  const onAdd = (data) => {
    // console.log(data);
    if(open === "Add"){
      dispatch(createItemAvailability({...data, branchId: branchId}))
      .then((res) => {
        if (res.payload.status === 200) {
          dispatch(showSnackBar("Meal Created succesfully"));
        } else {
          dispatch(
            showSnackBar(
              getErrorMessage(res) || "Failed to Create",
              "error"
            )
          );
      }
    });
    }
    else if(open === "Edit"){
      dispatch(updateItemAvailability(data))
      .then((res) => {
        if (res.payload.status === 200) {
          dispatch(showSnackBar("Meal Updated succesfully"));
        } else {
          dispatch(
            showSnackBar(
              getErrorMessage(res) || "Failed to Create",
              "error"
            )
          );
      }
    });
    }
    dispatch(getAllItemAvailability())
    toggleAdd();
  };

  const AddAction = () => {
    return (
      <AddCommonAction onClick={() => toggleAdd("Add")} title={PageTitle} />
    );
  };

  const handleEdit = (data) => {
    toggleAdd("Edit");
    setActionData(data);
  };

  const handleDelete = (data) => {
    toggleAdd("Delete");
    setActionData(data);
  };

  const confirmDelete = () => {
    // console.log(actionData)
    dispatch(deleteItemAvailability(actionData.id))
    .then((res) => {
      if (res.payload.status === 200) {
        toggleAdd();
        dispatch(showSnackBar("Meal Deleted succesfully"));
        getAllItemAvailability();
      } else {
        dispatch(
          showSnackBar(
            getErrorMessage(res) || "Failed to Create",
            "error"
          )
        );
    } 
    });
  };

  const EditAction = (action) => (
    <EditCommonAction onClick={() => handleEdit(action.data)} />
  );

  const DeleteAction = (action) => (
    <DeleteCommonAction onClick={() => handleDelete(action.data)} />
  );

  const headers = [
    {
      title: "Meals",
      key: "mealName",
    },

    { title: "Start Time", key: "mealStartTime", type: "time" },
    { title: "End Time", key: "mealEndTime", type: "time" },

  ];

  const defaultValues = {
  };

  React.useEffect(() => {
    dispatch(getAllItemAvailability());
  }, [open]);

  return (
    <>
      <div class="page-content-tab">
        <CommonAddModal
          title={PageTitle}
          open={open === "Add" || open === "Edit"}
          onClose={() => toggleAdd()}
          mode={open}
          onSubmit={(e) => onAdd(e)}
          data={actionData}
          formData={formData}
          defaultValue={defaultValues}
        />
        <DeleteModal
          size="md"
          open={open === "Delete"}
          title={actionData?.name}
          onClose={() => toggleAdd()}
          onConfirm={() => confirmDelete()}
        />
        <SmartTable
          title={PageTitle}
          paginated={true}
          headActions={[AddAction]}
          actions={actionLockSetting ? [] : [EditAction, DeleteAction]}
          tableData={itemAvailability}
          headers={headers}
        />
      </div>
    </>
  );
};

export default ManageItemAvailability;
