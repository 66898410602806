import React from "react";
import moment from "moment";

const TimeColumn = (date) => {

    const hour = moment(date.data).hour()
    const minute = moment(date.data).minute()

  return (     
        <div>
          <span>
            {hour < 10 ? `0${hour}` : hour} : {minute < 10 ? `0${minute}` : minute}
          </span>
        </div>
    );
};

export default TimeColumn;