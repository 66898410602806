import React from "react";

import ReactApexChart from "react-apexcharts";

const RenderChart = ({ data, showCards }) => {
  const chartOptions = {
    ...data.options,
    tooltip: {
      y: {
        formatter: (val) => val.toFixed(2),
      },
    },
    dataLabels: {
      formatter: (val) => val.toFixed(2),
    },
    yaxis: {
      labels: {
        formatter: (val) => val.toFixed(2), // Format y-axis values
      },
    },
  };

  return (
    <div
      class={
        showCards
          ? "card report-card d-flex flex-direction-row algin-items-center"
          : ""
      }
    >
      <div
        class={showCards ? "card-body" : ""}
        style={{ height: "100%", width: "100%" }}
      >
        <div id="chart">
          <ReactApexChart
            options={chartOptions}
            series={data.series}
            type={data.type}
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default RenderChart;
