import { uuid } from "uuidv4";
import { TYPESOFORDERS } from "../contants";

const dummyActive = (payload, username, orderNumber, branchOrderNumber) => {
    const { tableNumber, tableTypeId, tablePrice, orderTypeId, orderType } =
      payload;
    return {
      tableNumber: tableNumber,
      tableTypeId: tableTypeId,
      associatedPerson: username,
      orderItems: [],
      tablePrice: tablePrice || 0,
      orderTypeId: orderTypeId || TYPESOFORDERS[0].value,
      orderType: orderType || TYPESOFORDERS[0].key,
      branchOrderNumber: branchOrderNumber,
      orderNumber: orderNumber,
  
      otherCharges: 0,
      discount: 0,
      lastKOTItems: [],
      KOTS: [],
      refId: uuid(),
    };
};
export default dummyActive;