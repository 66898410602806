import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pushItemToActiveOrder, pushToppingToItem } from "../../../redux/action/orderActions";
import RightPortion from "../RightPortion";
import AvailableItemsList from "./AvailableItemsList";
import HotKeySelector from "./HotKeySelector";
import ItemCategorySelector from "./ItemCategorySelector";
import KeyboardInputs from "./KeyboardInputs";
import SearchItemSelector from "./SearchItemSelector";
import moment from "moment";
import { pushItemToActiveOrderOffline } from "../../../redux/action/offlineDataActions";

const styles = {
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "97%",
    width: "100%",
    padding: "10px",
    paddingLeft: 0,
    backgroundColor: "white",
    boxShadow: "0px 2px 4px rgb(31 30 47 / 10%)",
    overflow: "hidden",
  },
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "10px",
    height: "97%",
    paddingTop: 0,
    paddingBottom: 0,
    overflow: "hidden",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
};

const CenterPortion = () => {
  const dispatch = useDispatch();
  const {isOnline, isAppOffline} = useSelector((state) => state.networkStatus);
  const isElectron = window?.api?.isElectron;
  const [selectedCategory, setSelectedCategory] = React.useState("all");

  const [searchItemQuery, setSearchItemQuery] = React.useState();

  const {
    allItems,
    activeOrders,
    activeOrderIndex,
    selectedOrderType,
    selectedOrderTypeId,
  } = useSelector((state) => state.order);

  const activeOrder = useSelector((state) => state.order.activeOrder);
  const { tables } = useSelector((state) => state.branch);

  const { itemAvailability } = useSelector((state) => state.common);
  
  const active = activeOrders.find((order) => order.refId === activeOrder);
  let tableType = tables.filter((table) => {return table?.tableNumber === active?.tableNumber});
  // console.log(tableType);
  // console.log(active);
  if(active?.orderType === "Home Delivery"){
    tableType = [{ tableType: "Online"}];
  }

  const getFilteredCategoryItems = () => {
    let items = []
    let itemsWithTime = []

    let currentHour = moment().hour()
    let meal = itemAvailability.filter((item) => (moment(item.mealStartTime).hour() <= currentHour 
    && moment(item.mealEndTime).hour() >= currentHour) )

    let mealNames = meal.map((item) => item?.mealName)

    allItems.forEach((item) => {
      if(item.itemAvailability.length > 0){
        let data = item.itemAvailability.filter((item) => mealNames?.includes(item?.mealName))
        if(data.length > 0){
          itemsWithTime.push(item)
        }
      }  
      else{
        items.push(item)
      }    
    });

    items = [...items, ...itemsWithTime]

    if (searchItemQuery) {
      return items.filter((item) => {
        return item.itemName
          ?.toLowerCase()
          .includes(searchItemQuery?.toLowerCase());
      });
    }
    if (selectedCategory === "all") {
      return items;
    } else {
      return items.filter((item) => {
        return item.categoryName === selectedCategory;
      });
    }
  };

  const handleCategoryTypeFilter = (typeId) => {
    setSelectedCategory(typeId);
  };

  const handleSearch = (value) => {
    setSearchItemQuery(value);
  };

  const handleItemClick = (item, isVariant) => {
    // dispatch(pushItemToActiveOrder({ item, isVariant, tableType}));
    if((isAppOffline || !isOnline) && isElectron){
      dispatch(pushItemToActiveOrderOffline({ item, isVariant, tableType}));
    }else{
      dispatch(pushItemToActiveOrder({ item, isVariant, tableType}));
    }
  };
  const handleVariantClick = (item) => {
    handleItemClick(item, true);
  };
  const handleAddToppings = (toppings) => {
    // console.log(data);
    dispatch(pushToppingToItem({toppings, isVariant: true}));
  }
  // console.log("all items", allItems);
  return (
    <div style={styles.root}>
      <KeyboardInputs />
      {/* <HotKeySelector
        handleClick={(data) => handleItemClick(data)}
        items={allItems.filter((item) => item.hotKey)}
      /> */}
      <div style={styles.container}>
        <div style={styles.row}>
          <ItemCategorySelector
            selected={selectedCategory}
            setSelected={handleCategoryTypeFilter}
          />
          <SearchItemSelector
            searchQuery={searchItemQuery}
            handleSearch={handleSearch}
          />
        </div>
        <AvailableItemsList
          items={getFilteredCategoryItems()}
          onItemClick={handleItemClick}
          onVariantClick={handleVariantClick}
          tableType={tableType}
          onToppingSubmit={(data) => handleAddToppings(data)}
          isOnline={(!isAppOffline && isOnline)}
        />
      </div>
      <RightPortion />
    </div>
  );
};

export default CenterPortion;
